import { IColorOption } from "../interfaces/interface";

export const colorOptions: IColorOption[] = [
    {
        text: "White",
        value: "0",
        unicode: "#FFFFFF"
    },
    {
        text: "Green",
        value: "1",
        unicode: "#00FF00"
    },
    {
        text: "Yellow Green",
        value: "2",
        unicode: "#7FFF00"
    },
    {
        text: "Green Yellow",
        value: "3",
        unicode: "#DFFF00"
    },
    {
        text: "Yellow",
        value: "4",
        unicode: "#FFFF00"
    },
    {
        text: "Cyan",
        value: "5",
        unicode: "#00FFFF"
    },
    {
        text: "Pink",
        value: "6",
        unicode: "#FF00FF"
    },
    {
        text: "Red",
        value: "7",
        unicode: "#FF0000"
    },
    {
        text: "Custom",
        value: "8",
        unicode: ""
    }
]