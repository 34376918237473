import React, { useEffect, useRef, useState } from 'react';
import { CrosshairCanvasDisplayEnum, ICrosshairCanvasProps } from '../interfaces/interface';
import { colorOptions } from '../constants/settings';
import { Route, Routes, redirect, useNavigate } from "react-router-dom";

export function CrosshairCanvas(props: ICrosshairCanvasProps) {
    const crosshairCanvas = useRef<HTMLCanvasElement>(null);
    const canvasWrapper = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (canvasWrapper.current != null && crosshairCanvas.current != null) {
            if (props.displaySetting == CrosshairCanvasDisplayEnum.General || props.displaySetting == CrosshairCanvasDisplayEnum.Sniper) {
                const rects = canvasWrapper.current.getClientRects();
                crosshairCanvas.current!.height = rects[0].height;
                crosshairCanvas.current!.width = rects[0].width;
            }
            else {
                crosshairCanvas.current!.height = 128;
                crosshairCanvas.current!.width = 128;
            }
        }
        drawCanvas();
    }, [props.code, props.displaySetting])

    const hexToRgb = (hexCode: string, alpha: string) => {
        if (hexCode.startsWith("#")) {
            hexCode = hexCode.replace("#", "");
        }
        var bigint = parseInt(hexCode, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return "rgba(" + r + "," + g + "," + b + "," + alpha + ")";
    }

    const drawTextBG = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number) => {
        ctx.font = "bold 16px Segoe UI";
        ctx.textBaseline = 'top';
        const width = ctx.measureText(text).width;
        ctx.fillStyle = hexToRgb("#000000", "0.5");
        ctx.fillRect(x - (width / 2) - 5, y, width + 10, 28);
        ctx.fillStyle = "#ffffff"
        ctx.fillText(text, x - (width / 2), y + 7);
    }

    const drawCanvas = () => {
        if (props.code != undefined) {
            const advancedOptions = props.code.includes(";s;1");
            const adscopyPrimaryCrosshair = !props.code.includes("p;0");

            let codes = props.code.split("A;");
            let adsCode = "";
            if (codes.length > 1) {
                adsCode = codes[1].split("S;")[0];
            }
            let primaryCode = codes[0];
            codes = primaryCode.split("S;");
            primaryCode = codes[0];

            let color = colorOptions[0].unicode;

            if (primaryCode.includes("c;")) {
                const primaryColorIndex = parseInt(primaryCode.split("c;")[1][0]);
                color = colorOptions[primaryColorIndex].unicode;
                if (primaryColorIndex == 8) {
                    let primaryColorUnicode = primaryCode.split("u;")[1].split(";")[0];
                    color = "#" + primaryColorUnicode.substring(0, primaryColorUnicode.length - 2);
                }
            }

            const outlines = !primaryCode.includes("h;0")

            let primaryOutlineThicknessOption = "1";
            if (primaryCode.includes(";t;")) {
                primaryOutlineThicknessOption = primaryCode.split(";t;")[1].split(";")[0];
            }

            let primaryOutlineOpacityOption = "0.5";
            if (primaryCode.includes(";o;")) {
                primaryOutlineOpacityOption = primaryCode.split(";o;")[1].split(";")[0];
            }

            const centerDot = primaryCode.includes("d;1");

            let primaryCenterDotThicknessOption = "2";
            if (primaryCode.includes(";z;")) {
                primaryCenterDotThicknessOption = primaryCode.split(";z;")[1].split(";")[0];
            }

            let primaryCenterDotOpacityOption = "1";
            if (primaryCode.includes(";a;")) {
                primaryCenterDotOpacityOption = primaryCode.split(";a;")[1].split(";")[0];
            }

            const primaryOverrideFiringErrorOffsetWithOffset = primaryCode.includes("m;1");

            const showInnerLines = !primaryCode.includes("0b;0");

            let primaryInnerLineThicknessOption = "2";
            if (primaryCode.includes(";0t;")) {
                primaryInnerLineThicknessOption = primaryCode.split(";0t;")[1].split(";")[0];
            }

            let primaryInnerLineLengthXOption = "6";
            if (primaryCode.includes(";0l;")) {
                primaryInnerLineLengthXOption = primaryCode.split(";0l;")[1].split(";")[0];
            }

            let primaryInnerLineLengthYOption = "6";
            if (!primaryCode.includes("0g;1") && primaryCode.includes(";0l;")) {
                primaryInnerLineLengthYOption = primaryCode.split(";0l;")[1].split(";")[0];
            }
            else if (primaryCode.includes(";0v;")) {
                primaryInnerLineLengthYOption = primaryCode.split(";0v;")[1].split(";")[0];
            }

            let primaryInnerLineOffsetOption = "3";
            if (primaryCode.includes(";0o;")) {
                primaryInnerLineOffsetOption = primaryCode.split(";0o;")[1].split(";")[0];
            }

            let primaryInnerLineOpacityOption = "0.8";
            if (primaryCode.includes(";0a;")) {
                primaryInnerLineOpacityOption = primaryCode.split(";0a;")[1].split(";")[0];
            }

            const innerLineMovementError = primaryCode.includes("0m;1");

            let primaryInnerLineMovementErrorMulitplierOption = "1";
            if (primaryCode.includes(";0s;")) {
                primaryInnerLineMovementErrorMulitplierOption = primaryCode.split(";0s;")[1].split(";")[0];
            }

            const innerLineFiringError = !primaryCode.includes("0f;0");

            let primaryInnerLineFiringErrorMulitplierOption = "1";
            if (primaryCode.includes(";0e;")) {
                primaryInnerLineFiringErrorMulitplierOption = primaryCode.split(";0e;")[1].split(";")[0];
            }

            const showOuterLines = !primaryCode.includes("1b;0");

            let primaryOuterLineThicknessOption = "2";
            if (primaryCode.includes(";1t;")) {
                primaryOuterLineThicknessOption = primaryCode.split(";1t;")[1].split(";")[0];
            }

            let primaryOuterLineLengthXOption = "2";
            if (primaryCode.includes(";1l;")) {
                primaryOuterLineLengthXOption = primaryCode.split(";1l;")[1].split(";")[0];
            }

            let primaryOuterLineLengthYOption = "2";
            if (!primaryCode.includes("1g;1") && primaryCode.includes(";1l;")) {
                primaryOuterLineLengthYOption = primaryCode.split(";1l;")[1].split(";")[0];
            }
            if (primaryCode.includes(";1v;")) {
                primaryOuterLineLengthYOption = primaryCode.split(";1v;")[1].split(";")[0];
            }

            const outerLineLengthLink = !primaryCode.includes("1g;1");

            let primaryOuterLineOffsetOption = "10";
            if (primaryCode.includes(";1o;")) {
                primaryOuterLineOffsetOption = primaryCode.split(";1o;")[1].split(";")[0];
            }

            let primaryOuterLineOpacityOption = "0.35";
            if (primaryCode.includes(";1a;")) {
                primaryOuterLineOpacityOption = primaryCode.split(";1a;")[1].split(";")[0];
            }

            let primaryOuterLineMovementErrorMulitplierOption = "1";
            if (primaryCode.includes(";1s;")) {
                primaryOuterLineMovementErrorMulitplierOption = primaryCode.split(";1s;")[1].split(";")[0];
            }

            const outerLineFiringError = !primaryCode.includes("1f;0");

            let primaryOuterLineFiringErrorMulitplierOption = "1";
            if (primaryCode.includes(";1e;")) {
                primaryOuterLineFiringErrorMulitplierOption = primaryCode.split(";1e;")[1].split(";")[0];
            }

            let adscolor = colorOptions[0].unicode;

            if (adsCode.includes("c;")) {
                const adsColorIndex = parseInt(adsCode.split("c;")[1][0]);
                adscolor = colorOptions[adsColorIndex].unicode;
                if (adsColorIndex == 8) {
                    let adsColorUnicode = adsCode.split("u;")[1].split(";")[0];
                    adsColorUnicode = "#" + adsColorUnicode.substring(0, adsColorUnicode.length - 2);
                    adscolor = adsColorUnicode;
                }
            }

            const adsOutlines = !adsCode.includes("h;0")

            let adsOutlineThicknessOption = "1";
            if (adsCode.includes(";t;")) {
                adsOutlineThicknessOption = adsCode.split(";t;")[1].split(";")[0];
            }

            let adsOutlineOpacityOption = "0.5";
            if (adsCode.includes(";o;")) {
                adsOutlineOpacityOption = adsCode.split(";o;")[1].split(";")[0];
            }

            const adsCenterDot = adsCode.includes("d;1");

            let adsCenterDotThicknessOption = "2";
            if (adsCode.includes(";z;")) {
                adsCenterDotThicknessOption = adsCode.split(";z;")[1].split(";")[0];
            }

            let adsCenterDotOpacityOption = "1";
            if (adsCode.includes(";a;")) {
                adsCenterDotOpacityOption = adsCode.split(";a;")[1].split(";")[0];
            }

            const adsOverrideFiringErrorOffsetWithOffset = adsCode.includes("m;1");

            const adsShowInnerLines = !adsCode.includes("0b;0");

            let adsInnerLineThicknessOption = "2";
            if (adsCode.includes(";0t;")) {
                adsInnerLineThicknessOption = adsCode.split(";0t;")[1].split(";")[0];
            }

            let adsInnerLineLengthXOption = "6";
            if (adsCode.includes(";0l;")) {
                adsInnerLineLengthXOption = adsCode.split(";0l;")[1].split(";")[0];
            }

            let adsInnerLineLengthYOption = "6";
            if (!adsCode.includes("0g;1") && adsCode.includes(";0l;")) {
                adsInnerLineLengthYOption = adsCode.split(";0l;")[1].split(";")[0];
            }
            if (adsCode.includes(";0v;")) {
                adsInnerLineLengthYOption = adsCode.split(";0v;")[1].split(";")[0];
            }

            let adsInnerLineOffsetOption = "3";
            if (adsCode.includes(";0o;")) {
                adsInnerLineOffsetOption = adsCode.split(";0o;")[1].split(";")[0];
            }

            let adsInnerLineOpacityOption = "0.8";
            if (adsCode.includes(";0a;")) {
                adsInnerLineOpacityOption = adsCode.split(";0a;")[1].split(";")[0];
            }

            let adsInnerLineMovementErrorMulitplierOption = "1";
            if (adsCode.includes(";0s;")) {
                adsInnerLineMovementErrorMulitplierOption = adsCode.split(";0s;")[1].split(";")[0];
            }

            const adsInnerLineFiringError = !adsCode.includes("0f;0");

            let adsInnerLineFiringErrorMulitplierOption = "1";
            if (adsCode.includes(";0e;")) {
                adsInnerLineFiringErrorMulitplierOption = adsCode.split(";0e;")[1].split(";")[0];
            }

            const adsShowOuterLines = !adsCode.includes("1b;0");

            let adsOuterLineThicknessOption = "2";
            if (adsCode.includes(";1t;")) {
                adsOuterLineThicknessOption = adsCode.split(";1t;")[1].split(";")[0];
            }

            let adsOuterLineLengthXOption = "2";
            if (adsCode.includes(";1l;")) {
                adsOuterLineLengthXOption = adsCode.split(";1l;")[1].split(";")[0];
            }

            let adsOuterLineLengthYOption = "2";
            if (!adsCode.includes("1g;1") && adsCode.includes(";1l;")) {
                adsOuterLineLengthYOption = adsCode.split(";1l;")[1].split(";")[0];
            }
            if (adsCode.includes(";1v;")) {
                adsOuterLineLengthYOption = adsCode.split(";1v;")[1].split(";")[0];
            }

            let adsOuterLineOffsetOption = "10";
            if (adsCode.includes(";1o;")) {
                adsOuterLineOffsetOption = adsCode.split(";1o;")[1].split(";")[0];
            }

            let adsOuterLineOpacityOption = "0.35";
            if (adsCode.includes(";1a;")) {
                adsOuterLineOpacityOption = adsCode.split(";1a;")[1].split(";")[0];
            }

            let adsOuterLineMovementErrorMulitplierOption = "1";
            if (adsCode.includes(";1s;")) {
                adsOuterLineMovementErrorMulitplierOption = adsCode.split(";1s;")[1].split(";")[0];
            }

            const adsOuterLineFiringError = !adsCode.includes("1f;0");

            let adsOuterLineFiringErrorMulitplierOption = "1";
            if (adsCode.includes(";1e;")) {
                adsOuterLineFiringErrorMulitplierOption = adsCode.split(";1e;")[1].split(";")[0];
            }

            let sniperColor = colorOptions[7].unicode;
            let sniperCenterDot = true;
            let sniperCenterDotThickness = "1";
            let sniperCenterDotOpacity = "0.75";

            if (props.code.includes("S;")) {
                const sniperCode = props.code.split("S;")[1];

                if (sniperCode.includes("c;")) {
                    const sniperColorIndex = parseInt(sniperCode.split("c;")[1][0]);
                    sniperColor = colorOptions[sniperColorIndex].unicode;

                    if (sniperColorIndex == 8 && sniperCode.includes("t;")) {
                        let sniperColorUnicode = sniperCode.split("t;")[1].split(";")[0];
                        sniperColorUnicode = "#" + sniperColorUnicode.substring(0, sniperColorUnicode.length - 2);
                        sniperColor = sniperColorUnicode;
                    }
                }

                sniperCenterDot = !sniperCode.includes("d;0");

                if (sniperCode.includes("s;")) {
                    sniperCenterDotThickness = sniperCode.split("s;")[1].split(";")[0];
                }

                if (sniperCode.includes("o;")) {
                    sniperCenterDotOpacity = sniperCode.split("o;")[1].split(";")[0];
                }
            }

            const ctx = crosshairCanvas.current!.getContext("2d")!;
            ctx.clearRect(0, 0, crosshairCanvas.current!.width, crosshairCanvas.current!.height);

            let x = crosshairCanvas.current!.width / 2;
            let y = crosshairCanvas.current!.height / 2;
            ctx.lineCap = "square";

            if (props.displaySetting == CrosshairCanvasDisplayEnum.Primary || props.displaySetting == CrosshairCanvasDisplayEnum.Preview
                || (props.displaySetting == CrosshairCanvasDisplayEnum.Advanced && adscopyPrimaryCrosshair == true)
                || props.displaySetting == CrosshairCanvasDisplayEnum.General) {
                if (props.displaySetting == CrosshairCanvasDisplayEnum.General && advancedOptions == true) {
                    x = crosshairCanvas.current!.width / 6;
                    y = crosshairCanvas.current!.height / 2;
                }
                const innerOffset = parseInt(primaryInnerLineOffsetOption) + (!primaryOverrideFiringErrorOffsetWithOffset && innerLineFiringError ? 4 : 0);
                const innerXLength = parseInt(primaryInnerLineLengthXOption);
                const innerYLength = parseInt(primaryInnerLineLengthYOption);
                const innerLineThickness = parseInt(primaryInnerLineThicknessOption);

                const outerOffset = parseInt(primaryOuterLineOffsetOption) + (!primaryOverrideFiringErrorOffsetWithOffset && outerLineFiringError ? 4 : 0);
                const outerXLength = parseInt(primaryOuterLineLengthXOption);
                const outerYLength = parseInt(primaryOuterLineLengthYOption);
                const outerLineThickness = parseInt(primaryOuterLineThicknessOption);
                const outlineThickness = parseInt(primaryOutlineThicknessOption);
                ctx.lineWidth = outlineThickness;
                ctx.strokeStyle = hexToRgb("000000", primaryOutlineOpacityOption);
                const outlineStartOffset = Math.ceil(outlineThickness / 2) - (outlineThickness % 2 == 0 ? 0 : 0.5);

                if (showInnerLines) {
                    ctx.fillStyle = hexToRgb(color, primaryInnerLineOpacityOption);
                    ctx.beginPath();
                    if (innerXLength != 0) {
                        ctx.fillRect(x + innerOffset, y - Math.ceil(innerLineThickness / 2), innerXLength, innerLineThickness); // right
                        if (outlines) {
                            ctx.strokeRect(x + innerOffset - outlineStartOffset, y - Math.ceil(innerLineThickness / 2) - outlineStartOffset, innerXLength + outlineThickness, innerLineThickness + outlineThickness); // right
                        }
                        ctx.fillRect(x - innerOffset - innerXLength - ((innerLineThickness % 2 == 0) ? 0 : 1), y - Math.ceil(innerLineThickness / 2), innerXLength, innerLineThickness); // left
                        if (outlines) {
                            ctx.strokeRect(x - innerOffset - innerXLength - ((innerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, y - Math.ceil(innerLineThickness / 2) - outlineStartOffset, innerXLength + outlineThickness, innerLineThickness + outlineThickness); // left
                        }
                    }
                    if (innerYLength != 0) {
                        ctx.fillRect(x - Math.ceil(innerLineThickness / 2), y - innerOffset - innerYLength - ((innerLineThickness % 2 == 0) ? 0 : 1), innerLineThickness, innerYLength); // top
                        if (outlines) {
                            ctx.strokeRect(x - Math.ceil(innerLineThickness / 2) - outlineStartOffset, y - innerOffset - innerYLength - ((innerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, innerLineThickness + outlineThickness, innerYLength + outlineThickness); // top
                        }
                        ctx.fillRect(x - Math.ceil(innerLineThickness / 2), y + innerOffset, innerLineThickness, innerYLength); // bottom
                        if (outlines) {
                            ctx.strokeRect(x - Math.ceil(innerLineThickness / 2) - outlineStartOffset, y + innerOffset - outlineStartOffset, innerLineThickness + outlineThickness, innerYLength + outlineThickness); // bottom
                        }
                    }
                    ctx.closePath();
                }

                if (centerDot) {
                    const centerDotThickness = parseInt(primaryCenterDotThicknessOption);
                    ctx.fillStyle = hexToRgb(color, primaryCenterDotOpacityOption);
                    ctx.beginPath();
                    ctx.fillRect(x - Math.ceil(centerDotThickness / 2), y - Math.ceil(centerDotThickness / 2), centerDotThickness, centerDotThickness);
                    if (outlines) {
                        ctx.strokeRect(x - outlineStartOffset - Math.ceil(centerDotThickness / 2), y - Math.ceil(centerDotThickness / 2) - outlineStartOffset, centerDotThickness + outlineThickness, centerDotThickness + outlineThickness);
                    }
                    ctx.closePath();
                }

                if (showOuterLines) {
                    ctx.fillStyle = hexToRgb(color, primaryOuterLineOpacityOption);
                    ctx.beginPath();
                    ctx.fillRect(x + outerOffset, y - Math.ceil(outerLineThickness / 2), outerXLength, outerLineThickness); // right
                    if (outerXLength != 0) {
                        if (outlines) {
                            ctx.strokeRect(x + outerOffset - outlineStartOffset, y - Math.ceil(outerLineThickness / 2) - outlineStartOffset, outerXLength + outlineThickness, outerLineThickness + outlineThickness); // right
                        }
                        ctx.fillRect(x - outerOffset - outerXLength - ((outerLineThickness % 2 == 0) ? 0 : 1), y - Math.ceil(outerLineThickness / 2), outerXLength, outerLineThickness); // left
                        if (outlines) {
                            ctx.strokeRect(x - outerOffset - outerXLength - ((outerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, y - Math.ceil(outerLineThickness / 2) - outlineStartOffset, outerXLength + outlineThickness, outerLineThickness + outlineThickness); // left
                        }
                    }
                    if (outerYLength != 0) {
                        ctx.fillRect(x - Math.ceil(outerLineThickness / 2), y + outerOffset, outerLineThickness, outerYLength); // bottom
                        if (outlines) {
                            ctx.strokeRect(x - Math.ceil(outerLineThickness / 2) - outlineStartOffset, y + outerOffset - outlineStartOffset, outerLineThickness + outlineThickness, outerYLength + outlineThickness); // bottom
                        }
                        ctx.fillRect(x - Math.ceil(outerLineThickness / 2), y - outerOffset - outerYLength - ((outerLineThickness % 2 == 0) ? 0 : 1), outerLineThickness, outerYLength); // top
                        if (outlines) {
                            ctx.strokeRect(x - Math.ceil(outerLineThickness / 2) - outlineStartOffset, y - outerOffset - outerYLength - ((outerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, outerLineThickness + outlineThickness, outerYLength + outlineThickness); // top
                        }
                    }
                    ctx.closePath();
                }
            }

            if ((props.displaySetting == CrosshairCanvasDisplayEnum.Advanced && !adscopyPrimaryCrosshair) || (props.displaySetting == CrosshairCanvasDisplayEnum.General && advancedOptions == true)) {
                if (props.displaySetting == CrosshairCanvasDisplayEnum.General && advancedOptions == true) {
                    x = crosshairCanvas.current!.width / 2;
                    y = crosshairCanvas.current!.height / 2;
                }

                if (adscopyPrimaryCrosshair == true) {
                    const innerOffset = parseInt(primaryInnerLineOffsetOption) + (!primaryOverrideFiringErrorOffsetWithOffset && innerLineFiringError ? 4 : 0);
                    const innerXLength = parseInt(primaryInnerLineLengthXOption);
                    const innerYLength = parseInt(primaryInnerLineLengthYOption);
                    const innerLineThickness = parseInt(primaryInnerLineThicknessOption);

                    const outerOffset = parseInt(primaryOuterLineOffsetOption) + (!primaryOverrideFiringErrorOffsetWithOffset && outerLineFiringError ? 4 : 0);
                    const outerXLength = parseInt(primaryOuterLineLengthXOption);
                    const outerYLength = parseInt(primaryOuterLineLengthYOption);
                    const outerLineThickness = parseInt(primaryOuterLineThicknessOption);
                    const outlineThickness = parseInt(primaryOutlineThicknessOption);
                    ctx.lineWidth = outlineThickness;
                    ctx.strokeStyle = hexToRgb("000000", primaryOutlineOpacityOption);
                    const outlineStartOffset = Math.ceil(outlineThickness / 2) - (outlineThickness % 2 == 0 ? 0 : 0.5);

                    if (showInnerLines) {
                        ctx.fillStyle = hexToRgb(color, primaryInnerLineOpacityOption);
                        ctx.beginPath();
                        if (innerXLength != 0) {
                            ctx.fillRect(x + innerOffset, y - Math.ceil(innerLineThickness / 2), innerXLength, innerLineThickness); // right
                            if (outlines) {
                                ctx.strokeRect(x + innerOffset - outlineStartOffset, y - Math.ceil(innerLineThickness / 2) - outlineStartOffset, innerXLength + outlineThickness, innerLineThickness + outlineThickness); // right
                            }
                            ctx.fillRect(x - innerOffset - innerXLength - ((innerLineThickness % 2 == 0) ? 0 : 1), y - Math.ceil(innerLineThickness / 2), innerXLength, innerLineThickness); // left
                            if (outlines) {
                                ctx.strokeRect(x - innerOffset - innerXLength - ((innerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, y - Math.ceil(innerLineThickness / 2) - outlineStartOffset, innerXLength + outlineThickness, innerLineThickness + outlineThickness); // left
                            }
                        }
                        if (innerYLength != 0) {
                            ctx.fillRect(x - Math.ceil(innerLineThickness / 2), y - innerOffset - innerYLength - ((innerLineThickness % 2 == 0) ? 0 : 1), innerLineThickness, innerYLength); // top
                            if (outlines) {
                                ctx.strokeRect(x - Math.ceil(innerLineThickness / 2) - outlineStartOffset, y - innerOffset - innerYLength - ((innerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, innerLineThickness + outlineThickness, innerYLength + outlineThickness); // top
                            }
                            ctx.fillRect(x - Math.ceil(innerLineThickness / 2), y + innerOffset, innerLineThickness, innerYLength); // bottom
                            if (outlines) {
                                ctx.strokeRect(x - Math.ceil(innerLineThickness / 2) - outlineStartOffset, y + innerOffset - outlineStartOffset, innerLineThickness + outlineThickness, innerYLength + outlineThickness); // bottom
                            }
                        }
                        ctx.closePath();
                    }

                    if (centerDot) {
                        const centerDotThickness = parseInt(primaryCenterDotThicknessOption);
                        ctx.fillStyle = hexToRgb(color, primaryCenterDotOpacityOption);
                        ctx.beginPath();
                        ctx.fillRect(x - Math.ceil(centerDotThickness / 2), y - Math.ceil(centerDotThickness / 2), centerDotThickness, centerDotThickness);
                        if (outlines) {
                            ctx.strokeRect(x - outlineStartOffset - Math.ceil(centerDotThickness / 2), y - Math.ceil(centerDotThickness / 2) - outlineStartOffset, centerDotThickness + outlineThickness, centerDotThickness + outlineThickness);
                        }
                        ctx.closePath();
                    }

                    if (showOuterLines) {
                        ctx.fillStyle = hexToRgb(color, primaryOuterLineOpacityOption);
                        ctx.beginPath();
                        ctx.fillRect(x + outerOffset, y - Math.ceil(outerLineThickness / 2), outerXLength, outerLineThickness); // right
                        if (outerXLength != 0) {
                            if (outlines) {
                                ctx.strokeRect(x + outerOffset - outlineStartOffset, y - Math.ceil(outerLineThickness / 2) - outlineStartOffset, outerXLength + outlineThickness, outerLineThickness + outlineThickness); // right
                            }
                            ctx.fillRect(x - outerOffset - outerXLength - ((outerLineThickness % 2 == 0) ? 0 : 1), y - Math.ceil(outerLineThickness / 2), outerXLength, outerLineThickness); // left
                            if (outlines) {
                                ctx.strokeRect(x - outerOffset - outerXLength - ((outerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, y - Math.ceil(outerLineThickness / 2) - outlineStartOffset, outerXLength + outlineThickness, outerLineThickness + outlineThickness); // left
                            }
                        }
                        if (outerYLength != 0) {
                            ctx.fillRect(x - Math.ceil(outerLineThickness / 2), y + outerOffset, outerLineThickness, outerYLength); // bottom
                            if (outlines) {
                                ctx.strokeRect(x - Math.ceil(outerLineThickness / 2) - outlineStartOffset, y + outerOffset - outlineStartOffset, outerLineThickness + outlineThickness, outerYLength + outlineThickness); // bottom
                            }
                            ctx.fillRect(x - Math.ceil(outerLineThickness / 2), y - outerOffset - outerYLength - ((outerLineThickness % 2 == 0) ? 0 : 1), outerLineThickness, outerYLength); // top
                            if (outlines) {
                                ctx.strokeRect(x - Math.ceil(outerLineThickness / 2) - outlineStartOffset, y - outerOffset - outerYLength - ((outerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, outerLineThickness + outlineThickness, outerYLength + outlineThickness); // top
                            }
                        }
                        ctx.closePath();
                    }
                }
                else {
                    const innerOffset = parseInt(adsInnerLineOffsetOption) + (!adsOverrideFiringErrorOffsetWithOffset && adsInnerLineFiringError ? 4 : 0);
                    const innerXLength = parseInt(adsInnerLineLengthXOption);
                    const innerYLength = parseInt(adsInnerLineLengthYOption);
                    const innerLineThickness = parseInt(adsInnerLineThicknessOption);

                    const outerOffset = parseInt(adsOuterLineOffsetOption) + (!adsOverrideFiringErrorOffsetWithOffset && adsOuterLineFiringError ? 4 : 0);
                    const outerXLength = parseInt(adsOuterLineLengthXOption);
                    const outerYLength = parseInt(adsOuterLineLengthYOption);
                    const outerLineThickness = parseInt(adsOuterLineThicknessOption);
                    const outlineThickness = parseInt(adsOutlineThicknessOption);
                    ctx.lineWidth = outlineThickness;
                    ctx.strokeStyle = hexToRgb("000000", adsOutlineOpacityOption);
                    const outlineStartOffset = Math.ceil(outlineThickness / 2) - (outlineThickness % 2 == 0 ? 0 : 0.5);

                    if (adsShowInnerLines) {
                        ctx.fillStyle = hexToRgb(adscolor, adsInnerLineOpacityOption);
                        ctx.beginPath();
                        if (innerXLength != 0) {
                            ctx.fillRect(x + innerOffset, y - Math.ceil(innerLineThickness / 2), innerXLength, innerLineThickness); // right
                            if (adsOutlines) {
                                ctx.strokeRect(x + innerOffset - outlineStartOffset, y - Math.ceil(innerLineThickness / 2) - outlineStartOffset, innerXLength + outlineThickness, innerLineThickness + outlineThickness); // right
                            }
                            ctx.fillRect(x - innerOffset - innerXLength - ((innerLineThickness % 2 == 0) ? 0 : 1), y - Math.ceil(innerLineThickness / 2), innerXLength, innerLineThickness); // left
                            if (adsOutlines) {
                                ctx.strokeRect(x - innerOffset - innerXLength - ((innerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, y - Math.ceil(innerLineThickness / 2) - outlineStartOffset, innerXLength + outlineThickness, innerLineThickness + outlineThickness); // left
                            }
                        }
                        if (innerYLength != 0) {
                            ctx.fillRect(x - Math.ceil(innerLineThickness / 2), y - innerOffset - innerYLength - ((innerLineThickness % 2 == 0) ? 0 : 1), innerLineThickness, innerYLength); // top
                            if (adsOutlines) {
                                ctx.strokeRect(x - Math.ceil(innerLineThickness / 2) - outlineStartOffset, y - innerOffset - innerYLength - ((innerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, innerLineThickness + outlineThickness, innerYLength + outlineThickness); // top
                            }
                            ctx.fillRect(x - Math.ceil(innerLineThickness / 2), y + innerOffset, innerLineThickness, innerYLength); // bottom
                            if (adsOutlines) {
                                ctx.strokeRect(x - Math.ceil(innerLineThickness / 2) - outlineStartOffset, y + innerOffset - outlineStartOffset, innerLineThickness + outlineThickness, innerYLength + outlineThickness); // bottom
                            }
                            ctx.closePath();
                        }
                    }

                    if (adsCenterDot) {
                        const centerDotThickness = parseInt(adsCenterDotThicknessOption);
                        ctx.fillStyle = hexToRgb(adscolor, adsCenterDotOpacityOption);
                        ctx.beginPath();
                        ctx.fillRect(x - Math.ceil(centerDotThickness / 2), y - Math.ceil(centerDotThickness / 2), centerDotThickness, centerDotThickness);
                        if (adsOutlines) {
                            ctx.strokeRect(x - outlineStartOffset - Math.ceil(centerDotThickness / 2), y - Math.ceil(centerDotThickness / 2) - outlineStartOffset, centerDotThickness + outlineThickness, centerDotThickness + outlineThickness);
                        }
                        ctx.closePath();
                    }

                    if (adsShowOuterLines) {
                        ctx.fillStyle = hexToRgb(adscolor, adsOuterLineOpacityOption);
                        ctx.beginPath();
                        if (outerXLength != 0) {
                            ctx.fillRect(x + outerOffset, y - Math.ceil(outerLineThickness / 2), outerXLength, outerLineThickness); // right
                            if (adsOutlines) {
                                ctx.strokeRect(x + outerOffset - outlineStartOffset, y - Math.ceil(outerLineThickness / 2) - outlineStartOffset, outerXLength + outlineThickness, outerLineThickness + outlineThickness); // right
                            }
                            ctx.fillRect(x - outerOffset - outerXLength - ((outerLineThickness % 2 == 0) ? 0 : 1), y - Math.ceil(outerLineThickness / 2), outerXLength, outerLineThickness); // left
                            if (adsOutlines) {
                                ctx.strokeRect(x - outerOffset - outerXLength - ((outerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, y - Math.ceil(outerLineThickness / 2) - outlineStartOffset, outerXLength + outlineThickness, outerLineThickness + outlineThickness); // left
                            }
                        }
                        if (outerYLength != 0) {
                            ctx.fillRect(x - Math.ceil(outerLineThickness / 2), y + outerOffset, outerLineThickness, outerYLength); // bottom
                            if (adsOutlines) {
                                ctx.strokeRect(x - Math.ceil(outerLineThickness / 2) - outlineStartOffset, y + outerOffset - outlineStartOffset, outerLineThickness + outlineThickness, outerYLength + outlineThickness); // bottom
                            }
                            ctx.fillRect(x - Math.ceil(outerLineThickness / 2), y - outerOffset - outerYLength - ((outerLineThickness % 2 == 0) ? 0 : 1), outerLineThickness, outerYLength); // top
                            if (adsOutlines) {
                                ctx.strokeRect(x - Math.ceil(outerLineThickness / 2) - outlineStartOffset, y - outerOffset - outerYLength - ((outerLineThickness % 2 == 0) ? 0 : 1) - outlineStartOffset, outerLineThickness + outlineThickness, outerYLength + outlineThickness); // top
                            }
                        }
                        ctx.closePath();
                    }
                }

            }

            if (props.displaySetting == CrosshairCanvasDisplayEnum.Sniper || (props.displaySetting == CrosshairCanvasDisplayEnum.General && advancedOptions == true)) {
                if (props.displaySetting == CrosshairCanvasDisplayEnum.General && advancedOptions == true) {
                    x = (crosshairCanvas.current!.width / 6) * 5;
                    y = crosshairCanvas.current!.height / 2;
                }
                if (props.displaySetting == CrosshairCanvasDisplayEnum.Sniper) {
                    ctx.beginPath();
                    ctx.strokeStyle = hexToRgb("000000", "0.4");
                    ctx.moveTo(0, y);
                    ctx.lineTo(x * 2, y);
                    ctx.stroke();
                    ctx.moveTo(x, 0);
                    ctx.lineTo(x, y * 2);
                    ctx.stroke();
                    ctx.closePath();

                    ctx.beginPath();
                    ctx.lineWidth = 8;
                    ctx.strokeStyle = hexToRgb("000000", "0.8");
                    ctx.moveTo(0, y);
                    ctx.lineTo(crosshairCanvas.current!.width / 8, y);
                    ctx.stroke();
                    ctx.strokeStyle = hexToRgb("000000", "0.8");
                    ctx.moveTo((x * 2) - (crosshairCanvas.current!.width / 8), y);
                    ctx.lineTo(x * 2, y);
                    ctx.stroke();
                    ctx.closePath();
                }

                if (sniperCenterDot) {
                    ctx.beginPath();
                    ctx.fillStyle = hexToRgb(sniperColor, sniperCenterDotOpacity);
                    const radius = parseFloat(sniperCenterDotThickness) * 3
                    ctx.moveTo(x, y);
                    ctx.arc(x, y, radius, 0, 2 * Math.PI);
                    ctx.fill();
                    ctx.closePath();
                }
                else if (props.displaySetting == CrosshairCanvasDisplayEnum.General) {
                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = hexToRgb("000000", "0.4");
                    ctx.moveTo((crosshairCanvas.current!.width / 6) * 4, y);
                    ctx.lineTo(crosshairCanvas.current!.width, y);
                    ctx.stroke();
                    ctx.moveTo((crosshairCanvas.current!.width / 6) * 5, 0);
                    ctx.lineTo((crosshairCanvas.current!.width / 6) * 5, crosshairCanvas.current!.height);
                    ctx.stroke();
                    ctx.closePath();
                }
            }

            if (props.displaySetting == CrosshairCanvasDisplayEnum.General && advancedOptions == true) {
                drawTextBG(ctx, "PRIMARY", crosshairCanvas.current!.width / 6, 12);
                let adsTitle = "AIM DOWN SIGHTS";
                if (crosshairCanvas.current!.width < 400) {
                    adsTitle = "ADS";
                }
                drawTextBG(ctx, adsTitle, crosshairCanvas.current!.width / 2, 12);
                let sniperTitle = "SNIPER SCOPE";
                if (crosshairCanvas.current!.width < 400) {
                    sniperTitle = "SNIPER";
                }
                drawTextBG(ctx, sniperTitle, (crosshairCanvas.current!.width / 6) * 5, 12);
            }
        }
    }

    const redirectToEditor = () => {
        if (props.displaySetting == CrosshairCanvasDisplayEnum.Preview) {
            let url = "/editor?code=" + props.code;
            navigate(url);
        }
    }

    return (
        <React.Fragment>
            <div className={" " + (props.displaySetting == CrosshairCanvasDisplayEnum.Preview ? "crosshair-canvas-preview" : "crosshair-canvas-settings") + (props.displaySetting == CrosshairCanvasDisplayEnum.Sniper || props.displaySetting == CrosshairCanvasDisplayEnum.General ? " crosshair-canvas-settings-full" : "")} onClick={redirectToEditor}>
                <div ref={canvasWrapper} className="crosshair-canvas-wrapper">
                    <canvas id="crosshairCanvas" className="crosshair-canvas" ref={crosshairCanvas} height={128} width={128}></canvas>
                </div>
                {props.displaySetting == CrosshairCanvasDisplayEnum.Preview && <React.Fragment>
                    <label>
                        {props.name || ""}
                    </label>
                </React.Fragment>
                }

            </div>
        </React.Fragment>
    );
}