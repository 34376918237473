import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { CrosshairCanvasDisplayEnum, IColorOption, ICrosshairSettingsProps } from '../interfaces/interface';
import { colorOptions } from '../constants/settings';
import { CrosshairCanvas } from './crosshairCanvas';
import { toast } from 'react-toastify';

export function CrosshairSettings(props: ICrosshairSettingsProps) {


    const initialized = useRef<boolean>(false);
    const [code, setCode] = useState<string>("");

    const [activeTab, setActiveTab] = useState<string>("PRIMARY");

    const [crosshairDisplay, setCrosshairDisplay] = useState<CrosshairCanvasDisplayEnum>(CrosshairCanvasDisplayEnum.Primary);


    const [advancedOptions, setAdvancedOptions] = useState<boolean>(false);
    const [showCrosshairOfSpectatedPlayer, setShowCrosshairOfSpectatedPlayer] = useState<boolean>(true);
    const [hideCrosshairOnFiringError, setHideCrosshairOnFiringError] = useState<boolean>(true);

    const [color, setColor] = useState<IColorOption>({
        text: colorOptions[0].text,
        value: colorOptions[0].value,
        unicode: colorOptions[0].unicode,
        customUnicode: colorOptions[0].unicode,
        isValid: true
    });
    const [outlines, setOutlines] = useState<boolean>(true);
    const [outlineOpacity, setOutlineOpacity] = useState<string>("0.5");
    const [outlineThickness, setOutlineThickness] = useState<string>("1");
    const [centerDot, setCenterDot] = useState<boolean>(false);
    const [centerDotOpacity, setCenterDotOpacity] = useState<string>("1");
    const [centerDotThickness, setCenterDotThickness] = useState<string>("2");
    const [overrideFiringErrorOffsetWithOffset, setOverrideFiringErrorOffsetWithOffset] = useState<boolean>(false);

    const [showInnerLines, setShowInnerLines] = useState<boolean>(true);
    const [innerLineOpacity, setInnerLineOpacity] = useState<string>("0.8");
    const [innerLineLengthX, setInnerLineLengthX] = useState<string>("6");
    const [innerLineLengthLink, setInnerLineLengthLink] = useState<boolean>(true);
    const [innerLineLengthY, setInnerLineLengthY] = useState<string>("6");
    const [innerLineThickness, setInnerLineThickness] = useState<string>("2");
    const [innerLineOffset, setInnerLineOffset] = useState<string>("3");
    const [innerLineMovementError, setInnerLineMovementError] = useState<boolean>(false);
    const [innerLineMovementErrorMulitplier, setInnerLineMovementErrorMulitplier] = useState<string>("1");
    const [innerLineFiringError, setInnerLineFiringError] = useState<boolean>(true);
    const [innerLineFiringErrorMulitplier, setInnerLineFiringErrorMulitplier] = useState<string>("1");

    const [showOuterLines, setShowOuterLines] = useState<boolean>(true);
    const [outerLineOpacity, setOuterLineOpacity] = useState<string>("0.35");
    const [outerLineLengthX, setOuterLineLengthX] = useState<string>("2");
    const [outerLineLengthLink, setOuterLineLengthLink] = useState<boolean>(true);
    const [outerLineLengthY, setOuterLineLengthY] = useState<string>("2");
    const [outerLineThickness, setOuterLineThickness] = useState<string>("2");
    const [outerLineOffset, setOuterLineOffset] = useState<string>("10");
    const [outerLineMovementError, setOuterLineMovementError] = useState<boolean>(true);
    const [outerLineMovementErrorMulitplier, setOuterLineMovementErrorMulitplier] = useState<string>("1");
    const [outerLineFiringError, setOuterLineFiringError] = useState<boolean>(true);
    const [outerLineFiringErrorMulitplier, setOuterLineFiringErrorMulitplier] = useState<string>("1");

    const [adscopyPrimaryCrosshair, adssetCopyPrimaryCrosshair] = useState<boolean>(true);
    const [adscolor, adssetColor] = useState<IColorOption>({
        text: colorOptions[0].text,
        value: colorOptions[0].value,
        unicode: colorOptions[0].unicode,
        customUnicode: colorOptions[0].unicode,
        isValid: true
    });
    const [adsoutlines, adssetOutlines] = useState<boolean>(true);
    const [adsoutlineOpacity, adssetOutlineOpacity] = useState<string>("0.5");
    const [adsoutlineThickness, adssetOutlineThickness] = useState<string>("1");
    const [adscenterDot, adssetCenterDot] = useState<boolean>(false);
    const [adscenterDotOpacity, adssetCenterDotOpacity] = useState<string>("1");
    const [adscenterDotThickness, adssetCenterDotThickness] = useState<string>("2");
    const [adsoverrideFiringErrorOffsetWithOffset, adssetOverrideFiringErrorOffsetWithOffset] = useState<boolean>(false);

    const [adsshowInnerLines, adssetShowInnerLines] = useState<boolean>(true);
    const [adsinnerLineOpacity, adssetInnerLineOpacity] = useState<string>("0.8");
    const [adsinnerLineLengthX, adssetInnerLineLengthX] = useState<string>("6");
    const [adsinnerLineLengthLink, adssetInnerLineLengthLink] = useState<boolean>(true);
    const [adsinnerLineLengthY, adssetInnerLineLengthY] = useState<string>("6");
    const [adsinnerLineThickness, adssetInnerLineThickness] = useState<string>("2");
    const [adsinnerLineOffset, adssetInnerLineOffset] = useState<string>("3");
    const [adsinnerLineMovementError, adssetInnerLineMovementError] = useState<boolean>(false);
    const [adsinnerLineMovementErrorMulitplier, adssetInnerLineMovementErrorMulitplier] = useState<string>("1");
    const [adsinnerLineFiringError, adssetInnerLineFiringError] = useState<boolean>(true);
    const [adsinnerLineFiringErrorMulitplier, adssetInnerLineFiringErrorMulitplier] = useState<string>("1");

    const [adsshowOuterLines, adssetShowOuterLines] = useState<boolean>(true);
    const [adsouterLineOpacity, adssetOuterLineOpacity] = useState<string>("0.35");
    const [adsouterLineLengthX, adssetOuterLineLengthX] = useState<string>("2");
    const [adsouterLineLengthLink, adssetOuterLineLengthLink] = useState<boolean>(true);
    const [adsouterLineLengthY, adssetOuterLineLengthY] = useState<string>("2");
    const [adsouterLineThickness, adssetOuterLineThickness] = useState<string>("2");
    const [adsouterLineOffset, adssetOuterLineOffset] = useState<string>("10");
    const [adsouterLineMovementError, adssetOuterLineMovementError] = useState<boolean>(true);
    const [adsouterLineMovementErrorMulitplier, adssetOuterLineMovementErrorMulitplier] = useState<string>("1");
    const [adsouterLineFiringError, adssetOuterLineFiringError] = useState<boolean>(true);
    const [adsouterLineFiringErrorMulitplier, adssetOuterLineFiringErrorMulitplier] = useState<string>("1");

    const [sniperColor, setSniperColor] = useState<IColorOption>({
        text: colorOptions[7].text,
        value: colorOptions[7].value,
        unicode: colorOptions[7].unicode,
        customUnicode: colorOptions[7].unicode,
        isValid: true
    });
    const [sniperCenterDot, setSniperCenterDot] = useState<boolean>(true);
    const [sniperCenterDotOpacity, setSniperCenterDotOpacity] = useState<string>("0.75");
    const [sniperCenterDotThickness, setSniperCenterDotThickness] = useState<string>("1");

    useEffect(() => {
        setCode(props.initialCode || "0");
    }, [props.initialCode])

    useEffect(() => {
        if (code != undefined && code != "" && initialized.current == false) {
            initialized.current = true;
            arrangeProperties();
        }
    }, [code])

    useEffect(() => {
        if (initialized.current) {
            arrangeCode();
        }
    }, [advancedOptions,
        showCrosshairOfSpectatedPlayer,
        hideCrosshairOnFiringError,
        color,
        outlineOpacity,
        outlineThickness,
        outlines,
        centerDot,
        centerDotOpacity,
        centerDotThickness,
        overrideFiringErrorOffsetWithOffset,
        showInnerLines,
        innerLineOpacity,
        innerLineLengthX,
        innerLineLengthLink,
        innerLineLengthY,
        innerLineThickness,
        innerLineOffset,
        innerLineMovementError,
        innerLineMovementErrorMulitplier,
        innerLineFiringError,
        innerLineFiringErrorMulitplier,
        showOuterLines,
        outerLineOpacity,
        outerLineLengthX,
        outerLineLengthLink,
        outerLineLengthY,
        outerLineThickness,
        outerLineOffset,
        outerLineMovementError,
        outerLineMovementErrorMulitplier,
        outerLineFiringError,
        outerLineFiringErrorMulitplier,
        adscopyPrimaryCrosshair,
        adscolor,
        adsoutlineOpacity,
        adsoutlineThickness,
        adsoutlines,
        adscenterDot,
        adscenterDotOpacity,
        adscenterDotThickness,
        adsoverrideFiringErrorOffsetWithOffset,
        adsshowInnerLines,
        adsinnerLineOpacity,
        adsinnerLineLengthX,
        adsinnerLineLengthLink,
        adsinnerLineLengthY,
        adsinnerLineThickness,
        adsinnerLineOffset,
        adsinnerLineMovementError,
        adsinnerLineMovementErrorMulitplier,
        adsinnerLineFiringError,
        adsinnerLineFiringErrorMulitplier,
        adsshowOuterLines,
        adsouterLineOpacity,
        adsouterLineLengthX,
        adsouterLineLengthLink,
        adsouterLineLengthY,
        adsouterLineThickness,
        adsouterLineOffset,
        adsouterLineMovementError,
        adsouterLineMovementErrorMulitplier,
        adsouterLineFiringError,
        adsouterLineFiringErrorMulitplier,
        sniperColor,
        sniperCenterDot,
        sniperCenterDotOpacity,
        sniperCenterDotThickness
    ])

    const onColorChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedColor = {
            ...colorOptions.find((item: IColorOption) => {
                return item.value == event.target.value;
            })!
        }
        if (!selectedColor.unicode) {
            selectedColor.unicode = color.unicode;
        }
        setColor({
            text: selectedColor.text,
            value: selectedColor.value,
            unicode: selectedColor.unicode,
            customUnicode: selectedColor.unicode,
            isValid: true
        });
    }

    const onColorUnicodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            if (color?.unicode == event.target.value) {
                return;
            }
            let colorCode = event.target.value;
            if (!colorCode.startsWith("#")) {
                colorCode = "#" + colorCode;
            }
            const isValid = /^#[0-9A-F]{6}$/i.test(colorCode);
            setColor({
                text: "Custom",
                value: "8",
                unicode: "",
                customUnicode: event.target.value,
                isValid: isValid
            });
        }
    }


    const onOutlineOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOutlineOpacity(event.target.value);
    }

    const onOutlineOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            setOutlineOpacity("1");
        }
    }

    const onOutlineThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOutlineThickness(event.target.value);
    }

    const onOutlineThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 1 || value > 6) {
            setOutlineThickness("1");
        }
    }


    const onCenterDotOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCenterDotOpacity(event.target.value);
    }

    const onCenterDotOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            setCenterDotOpacity("1");
        }
    }

    const onCenterDotThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCenterDotThickness(event.target.value);
    }

    const onCenterDotThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 1 || value > 6) {
            setCenterDotThickness("1");
        }
    }

    const onInnerLineOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerLineOpacity(event.target.value);
    }

    const onInnerLineOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            setInnerLineOpacity("1");
        }
    }

    const onInnerLineLengthXChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerLineLengthX(event.target.value);
        if (innerLineLengthLink) {
            setInnerLineLengthY(event.target.value);
        }
    }

    const onInnerLineLengthXBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            setInnerLineLengthX("1");
            if (innerLineLengthLink) {
                setInnerLineLengthY("1");
            }
        }
    }

    const onInnerLineLengthYChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerLineLengthY(event.target.value);
    }

    const onInnerLineLengthYBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            setInnerLineLengthY("1");
        }
    }

    const onInnerLineThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerLineThickness(event.target.value);
    }

    const onInnerLineThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 10) {
            setInnerLineThickness("1");
        }
    }

    const onInnerLineOffsetChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerLineOffset(event.target.value);
    }

    const onInnerLineOffsetBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            setInnerLineOffset("1");
        }
    }

    const onInnerLineMovementErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerLineMovementErrorMulitplier(event.target.value);
    }

    const onInnerLineMovementErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            setInnerLineMovementErrorMulitplier("1");
        }
    }

    const onInnerLineFiringErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInnerLineFiringErrorMulitplier(event.target.value);
    }

    const onInnerLineFiringErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            setInnerLineFiringErrorMulitplier("1");
        }
    }

    const onOuterLineOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOuterLineOpacity(event.target.value);
    }

    const onOuterLineOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            setOuterLineOpacity("1");
        }
    }

    const onOuterLineLengthXChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOuterLineLengthX(event.target.value);
        if (outerLineLengthLink) {
            setOuterLineLengthY(event.target.value);
        }
    }

    const onOuterLineLengthXBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            setOuterLineLengthX("1");
            if (outerLineLengthLink) {
                setOuterLineLengthY("1");
            }
        }
    }

    const onOuterLineLengthYChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOuterLineLengthY(event.target.value);
    }

    const onOuterLineLengthYBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            setOuterLineLengthY("1");
        }
    }

    const onOuterLineThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOuterLineThickness(event.target.value);
    }

    const onOuterLineThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 10) {
            setOuterLineThickness("1");
        }
    }

    const onOuterLineOffsetChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOuterLineOffset(event.target.value);
    }

    const onOuterLineOffsetBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 40) {
            setOuterLineOffset("1");
        }
    }

    const onOuterLineMovementErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOuterLineMovementErrorMulitplier(event.target.value);
    }

    const onOuterLineMovementErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            setOuterLineMovementErrorMulitplier("1");
        }
    }

    const onOuterLineFiringErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOuterLineFiringErrorMulitplier(event.target.value);
    }

    const onOuterLineFiringErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            setOuterLineFiringErrorMulitplier("1");
        }
    }

    const onadsColorChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedColor = {
            ...colorOptions.find((item: IColorOption) => {
                return item.value == event.target.value;
            })!
        }
        if (!selectedColor.unicode) {
            selectedColor.unicode = adscolor.unicode;
        }
        adssetColor({
            text: selectedColor.text,
            value: selectedColor.value,
            unicode: selectedColor.unicode,
            customUnicode: selectedColor.unicode,
            isValid: true
        });
    }

    const onadsColorUnicodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            if (adscolor?.unicode == event.target.value) {
                return;
            }
            let colorCode = event.target.value;
            if (!colorCode.startsWith("#")) {
                colorCode = "#" + colorCode;
            }
            const isValid = /^#[0-9A-F]{6}$/i.test(colorCode);
            adssetColor({
                text: "Custom",
                value: "8",
                unicode: "",
                customUnicode: event.target.value,
                isValid: isValid
            });
        }
    }


    const onadsOutlineOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOutlineOpacity(event.target.value);
    }

    const onadsOutlineOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            adssetOutlineOpacity("1");
        }
    }

    const onadsOutlineThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOutlineThickness(event.target.value);
    }

    const onadsOutlineThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 1 || value > 6) {
            adssetOutlineThickness("1");
        }
    }


    const onadsCenterDotOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetCenterDotOpacity(event.target.value);
    }

    const onadsCenterDotOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            adssetCenterDotOpacity("1");
        }
    }

    const onadsCenterDotThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetCenterDotThickness(event.target.value);
    }

    const onadsCenterDotThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 1 || value > 6) {
            adssetCenterDotThickness("1");
        }
    }

    const onadsInnerLineOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetInnerLineOpacity(event.target.value);
    }

    const onadsInnerLineOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            adssetInnerLineOpacity("1");
        }
    }

    const onadsInnerLineLengthXChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetInnerLineLengthX(event.target.value);
        if (adsinnerLineLengthLink) {
            adssetInnerLineLengthY(event.target.value);
        }
    }

    const onadsInnerLineLengthXBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            adssetInnerLineLengthX("1");
            if (adsinnerLineLengthLink) {
                adssetInnerLineLengthY("1");
            }
        }
    }

    const onadsInnerLineLengthYChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetInnerLineLengthY(event.target.value);
    }

    const onadsInnerLineLengthYBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            adssetInnerLineLengthY("1");
        }
    }

    const onadsInnerLineThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetInnerLineThickness(event.target.value);
    }

    const onadsInnerLineThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 10) {
            adssetInnerLineThickness("1");
        }
    }

    const onadsInnerLineOffsetChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetInnerLineOffset(event.target.value);
    }

    const onadsInnerLineOffsetBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            adssetInnerLineOffset("1");
        }
    }

    const onadsInnerLineMovementErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetInnerLineMovementErrorMulitplier(event.target.value);
    }

    const onadsInnerLineMovementErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            adssetInnerLineMovementErrorMulitplier("1");
        }
    }

    const onadsInnerLineFiringErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetInnerLineFiringErrorMulitplier(event.target.value);
    }

    const onadsInnerLineFiringErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            adssetInnerLineFiringErrorMulitplier("1");
        }
    }

    const onadsOuterLineOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOuterLineOpacity(event.target.value);
    }

    const onadsOuterLineOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            adssetOuterLineOpacity("1");
        }
    }

    const onadsOuterLineLengthXChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOuterLineLengthX(event.target.value);
        if (adsouterLineLengthLink) {
            adssetOuterLineLengthY(event.target.value);
        }
    }

    const onadsOuterLineLengthXBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            adssetOuterLineLengthX("1");
            if (adsouterLineLengthLink) {
                adssetOuterLineLengthY("1");
            }
        }
    }

    const onadsOuterLineLengthYChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOuterLineLengthY(event.target.value);
    }

    const onadsOuterLineLengthYBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 20) {
            adssetOuterLineLengthY("1");
        }
    }

    const onadsOuterLineThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOuterLineThickness(event.target.value);
    }

    const onadsOuterLineThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 10) {
            adssetOuterLineThickness("1");
        }
    }

    const onadsOuterLineOffsetChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOuterLineOffset(event.target.value);
    }

    const onadsOuterLineOffsetBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 40) {
            adssetOuterLineOffset("1");
        }
    }

    const onadsOuterLineMovementErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOuterLineMovementErrorMulitplier(event.target.value);
    }

    const onadsOuterLineMovementErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            adssetOuterLineMovementErrorMulitplier("1");
        }
    }

    const onadsOuterLineFiringErrorMulitplierChange = (event: ChangeEvent<HTMLInputElement>) => {
        adssetOuterLineFiringErrorMulitplier(event.target.value);
    }

    const onadsOuterLineFiringErrorMulitplierBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 3) {
            adssetOuterLineFiringErrorMulitplier("1");
        }
    }

    const onSniperColorChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedColor = {
            ...colorOptions.find((item: IColorOption) => {
                return item.value == event.target.value;
            })!
        }
        if (!selectedColor.unicode) {
            selectedColor.unicode = sniperColor.unicode;
        }
        setSniperColor({
            text: selectedColor.text,
            value: selectedColor.value,
            unicode: selectedColor.unicode,
            customUnicode: selectedColor.unicode,
            isValid: true
        });
    }

    const onSniperColorUnicodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            if (sniperColor?.unicode == event.target.value) {
                return;
            }
            let colorCode = event.target.value;
            if (!colorCode.startsWith("#")) {
                colorCode = "#" + colorCode;
            }
            const isValid = /^#[0-9A-F]{6}$/i.test(colorCode);
            setSniperColor({
                text: "Custom",
                value: "8",
                unicode: "",
                customUnicode: event.target.value,
                isValid: isValid
            });
        }
    }

    const onSniperCenterDotOpacityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSniperCenterDotOpacity(event.target.value);
    }

    const onSniperCenterDotOpacityBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0 || value > 1) {
            setSniperCenterDotOpacity("1");
        }
    }

    const onSniperCenterDotThicknessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSniperCenterDotThickness(event.target.value);
    }

    const onSniperCenterDotThicknessBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value < 0 || value > 4) {
            setSniperCenterDotThickness("1");
        }
    }

    const arrangeProperties = () => {
        if (code) {
            setAdvancedOptions(code.includes(";s;1"));
            adssetCopyPrimaryCrosshair(!code.includes(";p;0"));
            setHideCrosshairOnFiringError(!code.includes(";f;0"));
            setShowCrosshairOfSpectatedPlayer(!code.includes(";s;0"));
            setOverrideFiringErrorOffsetWithOffset(code.includes(";m;1"));

            let codes = code.split("A;");
            let adsCode = "";
            if (codes.length > 1) {
                adsCode = codes[1].split("S;")[0];
            }
            let primaryCode = codes[0];
            codes = primaryCode.split("S;");
            primaryCode = codes[0];


            if (!primaryCode.includes("c;")) {
                setColor({
                    text: colorOptions[0].text,
                    value: colorOptions[0].value,
                    unicode: colorOptions[0].unicode,
                    customUnicode: colorOptions[0].unicode,
                    isValid: true
                });
            }
            else {
                const primaryColorIndex = parseInt(primaryCode.split("c;")[1][0]);
                const primaryColorOption = {
                    text: colorOptions[primaryColorIndex].text,
                    value: colorOptions[primaryColorIndex].value,
                    unicode: colorOptions[primaryColorIndex].unicode,
                    customUnicode: colorOptions[primaryColorIndex].unicode,
                    isValid: true
                };
                if (primaryColorIndex == 8) {
                    let primaryColorUnicode = primaryCode.split("u;")[1].split(";")[0];
                    primaryColorUnicode = "#" + primaryColorUnicode.substring(0, primaryColorUnicode.length - 2);
                    primaryColorOption.customUnicode = primaryColorUnicode;
                    primaryColorOption.isValid = /^#[0-9A-F]{6}$/i.test(primaryColorUnicode);
                }
                setColor(primaryColorOption);
            }

            setOutlines(!primaryCode.includes("h;0"));

            let primaryOutlineThicknessOption = "1";
            if (primaryCode.includes(";t;")) {
                primaryOutlineThicknessOption = primaryCode.split(";t;")[1].split(";")[0];
            }
            setOutlineThickness(primaryOutlineThicknessOption);

            let primaryOutlineOpacityOption = "0.5";
            if (primaryCode.includes(";o;")) {
                primaryOutlineOpacityOption = primaryCode.split(";o;")[1].split(";")[0];
            }
            setOutlineOpacity(primaryOutlineOpacityOption);

            setCenterDot(primaryCode.includes("d;1"));

            let primaryCenterDotThicknessOption = "2";
            if (primaryCode.includes(";z;")) {
                primaryCenterDotThicknessOption = primaryCode.split(";z;")[1].split(";")[0];
            }
            setCenterDotThickness(primaryCenterDotThicknessOption);

            let primaryCenterDotOpacityOption = "1";
            if (primaryCode.includes(";a;")) {
                primaryCenterDotOpacityOption = primaryCode.split(";a;")[1].split(";")[0];
            }
            setCenterDotOpacity(primaryCenterDotOpacityOption);

            setShowInnerLines(!primaryCode.includes("0b;0"));

            let primaryInnerLineThicknessOption = "2";
            if (primaryCode.includes(";0t;")) {
                primaryInnerLineThicknessOption = primaryCode.split(";0t;")[1].split(";")[0];
            }
            setInnerLineThickness(primaryInnerLineThicknessOption);

            let primaryInnerLineLengthXOption = "6";
            if (primaryCode.includes(";0l;")) {
                primaryInnerLineLengthXOption = primaryCode.split(";0l;")[1].split(";")[0];
            }
            setInnerLineLengthX(primaryInnerLineLengthXOption);

            let primaryInnerLineLengthYOption = "6";
            if (!primaryCode.includes("0g;1") && primaryCode.includes(";0l;")) {
                primaryInnerLineLengthYOption = primaryCode.split(";0l;")[1].split(";")[0];
            }
            else if (primaryCode.includes(";0v;")) {
                primaryInnerLineLengthYOption = primaryCode.split(";0v;")[1].split(";")[0];
            }
            setInnerLineLengthY(primaryInnerLineLengthYOption);

            setInnerLineLengthLink(!primaryCode.includes("0g;1"));

            let primaryInnerLineOffsetOption = "3";
            if (primaryCode.includes(";0o;")) {
                primaryInnerLineOffsetOption = primaryCode.split(";0o;")[1].split(";")[0];
            }
            setInnerLineOffset(primaryInnerLineOffsetOption);

            let primaryInnerLineOpacityOption = "0.8";
            if (primaryCode.includes(";0a;")) {
                primaryInnerLineOpacityOption = primaryCode.split(";0a;")[1].split(";")[0];
            }
            setInnerLineOpacity(primaryInnerLineOpacityOption);

            setInnerLineMovementError(primaryCode.includes("0m;1"));

            setInnerLineFiringError(!primaryCode.includes("0f;0"));

            let primaryInnerLineMovementErrorMulitplierOption = "1";
            if (primaryCode.includes(";0s;")) {
                primaryInnerLineMovementErrorMulitplierOption = primaryCode.split(";0s;")[1].split(";")[0];
            }
            setInnerLineMovementErrorMulitplier(primaryInnerLineMovementErrorMulitplierOption);

            let primaryInnerLineFiringErrorMulitplierOption = "1";
            if (primaryCode.includes(";0e;")) {
                primaryInnerLineFiringErrorMulitplierOption = primaryCode.split(";0e;")[1].split(";")[0];
            }
            setInnerLineFiringErrorMulitplier(primaryInnerLineFiringErrorMulitplierOption);

            setShowOuterLines(!primaryCode.includes("1b;0"));

            let primaryOuterLineThicknessOption = "2";
            if (primaryCode.includes(";1t;")) {
                primaryOuterLineThicknessOption = primaryCode.split(";1t;")[1].split(";")[0];
            }
            setOuterLineThickness(primaryOuterLineThicknessOption);

            let primaryOuterLineLengthXOption = "2";
            if (primaryCode.includes(";1l;")) {
                primaryOuterLineLengthXOption = primaryCode.split(";1l;")[1].split(";")[0];
            }
            setOuterLineLengthX(primaryOuterLineLengthXOption);

            let primaryOuterLineLengthYOption = "2";
            if (!primaryCode.includes("1g;1") && primaryCode.includes(";1l;")) {
                primaryOuterLineLengthYOption = primaryCode.split(";1l;")[1].split(";")[0];
            }
            if (primaryCode.includes(";1v;")) {
                primaryOuterLineLengthYOption = primaryCode.split(";1v;")[1].split(";")[0];
            }
            setOuterLineLengthY(primaryOuterLineLengthYOption);

            setOuterLineLengthLink(!primaryCode.includes("1g;1"));

            let primaryOuterLineOffsetOption = "10";
            if (primaryCode.includes(";1o;")) {
                primaryOuterLineOffsetOption = primaryCode.split(";1o;")[1].split(";")[0];
            }
            setOuterLineOffset(primaryOuterLineOffsetOption);

            let primaryOuterLineOpacityOption = "0.35";
            if (primaryCode.includes(";1a;")) {
                primaryOuterLineOpacityOption = primaryCode.split(";1a;")[1].split(";")[0];
            }
            setOuterLineOpacity(primaryOuterLineOpacityOption);

            setOuterLineMovementError(!primaryCode.includes("1m;0"));

            setOuterLineFiringError(!primaryCode.includes("1f;0"));

            let primaryOuterLineMovementErrorMulitplierOption = "1";
            if (primaryCode.includes(";1s;")) {
                primaryOuterLineMovementErrorMulitplierOption = primaryCode.split(";1s;")[1].split(";")[0];
            }
            setOuterLineMovementErrorMulitplier(primaryOuterLineMovementErrorMulitplierOption);

            let primaryOuterLineFiringErrorMulitplierOption = "1";
            if (primaryCode.includes(";1e;")) {
                primaryOuterLineFiringErrorMulitplierOption = primaryCode.split(";1e;")[1].split(";")[0];
            }
            setOuterLineFiringErrorMulitplier(primaryOuterLineFiringErrorMulitplierOption);

            if (!adsCode.includes("c;")) {
                adssetColor({
                    text: colorOptions[0].text,
                    value: colorOptions[0].value,
                    unicode: colorOptions[0].unicode,
                    customUnicode: colorOptions[0].unicode,
                    isValid: true
                });
            }
            else {
                const adsColorIndex = parseInt(adsCode.split("c;")[1][0]);
                const adsColorOption = {
                    text: colorOptions[adsColorIndex].text,
                    value: colorOptions[adsColorIndex].value,
                    unicode: colorOptions[adsColorIndex].unicode,
                    customUnicode: colorOptions[adsColorIndex].unicode,
                    isValid: true
                };
                if (adsColorIndex == 8) {
                    let adsColorUnicode = adsCode.split("u;")[1].split(";")[0];
                    adsColorUnicode = "#" + adsColorUnicode.substring(0, adsColorUnicode.length - 2);
                    adsColorOption.customUnicode = adsColorUnicode;
                    adsColorOption.isValid = /^#[0-9A-F]{6}$/i.test(adsColorUnicode);
                }
                adssetColor(adsColorOption);
            }

            adssetOutlines(!adsCode.includes("h;0"));

            let adsOutlineThicknessOption = "1";
            if (adsCode.includes(";t;")) {
                adsOutlineThicknessOption = adsCode.split(";t;")[1].split(";")[0];
            }
            adssetOutlineThickness(adsOutlineThicknessOption);

            let adsOutlineOpacityOption = "0.5";
            if (adsCode.includes(";o;")) {
                adsOutlineOpacityOption = adsCode.split(";o;")[1].split(";")[0];
            }
            adssetOutlineOpacity(adsOutlineOpacityOption);

            adssetCenterDot(adsCode.includes("d;1"));

            let adsCenterDotThicknessOption = "2";
            if (adsCode.includes(";z;")) {
                adsCenterDotThicknessOption = adsCode.split(";z;")[1].split(";")[0];
            }
            adssetCenterDotThickness(adsCenterDotThicknessOption);

            let adsCenterDotOpacityOption = "1";
            if (adsCode.includes(";a;")) {
                adsCenterDotOpacityOption = adsCode.split(";a;")[1].split(";")[0];
            }
            adssetCenterDotOpacity(adsCenterDotOpacityOption);

            adssetShowInnerLines(!adsCode.includes("0b;0"));

            let adsInnerLineThicknessOption = "2";
            if (adsCode.includes(";0t;")) {
                adsInnerLineThicknessOption = adsCode.split(";0t;")[1].split(";")[0];
            }
            adssetInnerLineThickness(adsInnerLineThicknessOption);

            let adsInnerLineLengthXOption = "6";
            if (adsCode.includes(";0l;")) {
                adsInnerLineLengthXOption = adsCode.split(";0l;")[1].split(";")[0];
            }
            adssetInnerLineLengthX(adsInnerLineLengthXOption);

            let adsInnerLineLengthYOption = "6";
            if (!adsCode.includes("0g;1") && adsCode.includes(";0l;")) {
                adsInnerLineLengthYOption = adsCode.split(";0l;")[1].split(";")[0];
            }
            if (adsCode.includes(";0v;")) {
                adsInnerLineLengthYOption = adsCode.split(";0v;")[1].split(";")[0];
            }
            adssetInnerLineLengthY(adsInnerLineLengthYOption);

            adssetInnerLineLengthLink(!adsCode.includes("0g;1"));

            let adsInnerLineOffsetOption = "3";
            if (adsCode.includes(";0o;")) {
                adsInnerLineOffsetOption = adsCode.split(";0o;")[1].split(";")[0];
            }
            adssetInnerLineOffset(adsInnerLineOffsetOption);

            let adsInnerLineOpacityOption = "0.8";
            if (adsCode.includes(";0a;")) {
                adsInnerLineOpacityOption = adsCode.split(";0a;")[1].split(";")[0];
            }
            adssetInnerLineOpacity(adsInnerLineOpacityOption);

            adssetInnerLineMovementError(adsCode.includes("0m;1"));

            adssetInnerLineFiringError(!adsCode.includes("0f;0"));

            let adsInnerLineMovementErrorMulitplierOption = "1";
            if (adsCode.includes(";0s;")) {
                adsInnerLineMovementErrorMulitplierOption = adsCode.split(";0s;")[1].split(";")[0];
            }
            adssetInnerLineMovementErrorMulitplier(adsInnerLineMovementErrorMulitplierOption);

            let adsInnerLineFiringErrorMulitplierOption = "1";
            if (adsCode.includes(";0e;")) {
                adsInnerLineFiringErrorMulitplierOption = adsCode.split(";0e;")[1].split(";")[0];
            }
            adssetInnerLineFiringErrorMulitplier(adsInnerLineFiringErrorMulitplierOption);

            adssetShowOuterLines(!adsCode.includes("1b;0"));

            let adsOuterLineThicknessOption = "2";
            if (adsCode.includes(";1t;")) {
                adsOuterLineThicknessOption = adsCode.split(";1t;")[1].split(";")[0];
            }
            adssetOuterLineThickness(adsOuterLineThicknessOption);

            let adsOuterLineLengthXOption = "2";
            if (adsCode.includes(";1l;")) {
                adsOuterLineLengthXOption = adsCode.split(";1l;")[1].split(";")[0];
            }
            adssetOuterLineLengthX(adsOuterLineLengthXOption);

            let adsOuterLineLengthYOption = "2";
            if (!adsCode.includes("1g;1") && adsCode.includes(";1l;")) {
                adsOuterLineLengthYOption = adsCode.split(";1l;")[1].split(";")[0];
            }
            if (adsCode.includes(";1v;")) {
                adsOuterLineLengthYOption = adsCode.split(";1v;")[1].split(";")[0];
            }
            adssetOuterLineLengthY(adsOuterLineLengthYOption);

            adssetOuterLineLengthLink(!adsCode.includes("1g;1"));

            let adsOuterLineOffsetOption = "10";
            if (adsCode.includes(";1o;")) {
                adsOuterLineOffsetOption = adsCode.split(";1o;")[1].split(";")[0];
            }
            adssetOuterLineOffset(adsOuterLineOffsetOption);

            let adsOuterLineOpacityOption = "0.35";
            if (adsCode.includes(";1a;")) {
                adsOuterLineOpacityOption = adsCode.split(";1a;")[1].split(";")[0];
            }
            adssetOuterLineOpacity(adsOuterLineOpacityOption);

            adssetOuterLineMovementError(!adsCode.includes("1m;0"));

            adssetOuterLineFiringError(!adsCode.includes("1f;0"));

            let adsOuterLineMovementErrorMulitplierOption = "1";
            if (adsCode.includes(";1s;")) {
                adsOuterLineMovementErrorMulitplierOption = adsCode.split(";1s;")[1].split(";")[0];
            }
            adssetOuterLineMovementErrorMulitplier(adsOuterLineMovementErrorMulitplierOption);

            let adsOuterLineFiringErrorMulitplierOption = "1";
            if (adsCode.includes(";1e;")) {
                adsOuterLineFiringErrorMulitplierOption = adsCode.split(";1e;")[1].split(";")[0];
            }
            adssetOuterLineFiringErrorMulitplier(adsOuterLineFiringErrorMulitplierOption);

            if (code.includes("S;")) {
                const sniperCode = code.split("S;")[1];
                if (!sniperCode.includes("c;")) {
                    setSniperColor({
                        text: colorOptions[7].text,
                        value: colorOptions[7].value,
                        unicode: colorOptions[7].unicode,
                        customUnicode: colorOptions[7].unicode,
                        isValid: true
                    });
                }
                else {
                    const sniperColorIndex = parseInt(sniperCode.split("c;")[1][0]);
                    const sniperColorOption = {
                        text: colorOptions[sniperColorIndex].text,
                        value: colorOptions[sniperColorIndex].value,
                        unicode: colorOptions[sniperColorIndex].unicode,
                        customUnicode: colorOptions[sniperColorIndex].unicode,
                        isValid: true
                    }

                    if (sniperColorIndex == 8 && sniperCode.includes("t;")) {
                        let sniperColorUnicode = sniperCode.split("t;")[1].split(";")[0];
                        sniperColorUnicode = "#" + sniperColorUnicode.substring(0, sniperColorUnicode.length - 2);
                        sniperColorOption.isValid = /^#[0-9A-F]{6}$/i.test(sniperColorUnicode);
                        sniperColorOption.customUnicode = sniperColorUnicode;
                    }
                    setSniperColor(sniperColorOption);
                }

                setSniperCenterDot(!sniperCode.includes("d;0"));
                let sniperCenterDotThickness = "1";
                if (sniperCode.includes("s;")) {
                    sniperCenterDotThickness = sniperCode.split("s;")[1].split(";")[0];
                }
                setSniperCenterDotThickness(sniperCenterDotThickness);
                let sniperCenterDotOpacity = "0.75";
                if (sniperCode.includes("o;")) {
                    sniperCenterDotOpacity = sniperCode.split("o;")[1].split(";")[0];
                }
                setSniperCenterDotOpacity(sniperCenterDotOpacity);
            }
            else {
                setSniperColor({
                    text: colorOptions[7].text,
                    value: colorOptions[7].value,
                    unicode: colorOptions[7].unicode,
                    customUnicode: colorOptions[7].unicode,
                    isValid: true
                });
                setSniperCenterDot(true);
                setSniperCenterDotOpacity("0.75");
                setSniperCenterDotThickness("1");
            }
        }
    }

    const arrangeCode = () => {
        let newCode = "";

        newCode = newCode + "0;"

        if (advancedOptions) {
            if (!adscopyPrimaryCrosshair) {
                newCode = newCode + "p;0;";
            }

            newCode = newCode + "s;1;";
        }

        let primaryOptionsModified = false;
        newCode = newCode + "PRIMARY";

        if (color.value == "8") {
            primaryOptionsModified = true;

            newCode = newCode + "c;" + color.value + ";";

            if (color.customUnicode && color.isValid) {
                newCode = newCode + "u;" + color.customUnicode.replace("#", "") + "FF;";
            }
        }
        else if (color.value != "0") {
            primaryOptionsModified = true;
            newCode = newCode + "c;" + color.value + ";";
        }

        if (!outlines) {
            primaryOptionsModified = true;
            newCode = newCode + "h;0;";
        }
        else {
            if (outlineThickness != "1") {
                primaryOptionsModified = true;
                newCode = newCode + "t;" + outlineThickness + ";";
            }

            if (outlineOpacity != "0.5") {
                primaryOptionsModified = true;
                newCode = newCode + "o;" + parseFloat(outlineOpacity).toString() + ";";
            }
        }

        if (color.value == "8") {
            primaryOptionsModified = true;
            newCode = newCode + "b;1;";
        }

        if (centerDot) {
            primaryOptionsModified = true;
            newCode = newCode + "d;1;";

            if (centerDotThickness != "2") {
                newCode = newCode + "z;" + centerDotThickness + ";";
            }

            const centerDotOpacityFloat = parseFloat(centerDotOpacity).toString();

            if (centerDotOpacityFloat != "1") {
                newCode = newCode + "a;" + centerDotOpacityFloat + ";";
            }
        }


        if (!hideCrosshairOnFiringError) {
            primaryOptionsModified = true;
            newCode = newCode + "f;0;"
        }

        if (!showCrosshairOfSpectatedPlayer) {
            primaryOptionsModified = true;
            newCode = newCode + "s;0;"
        }

        if (overrideFiringErrorOffsetWithOffset) {
            primaryOptionsModified = true;
            newCode = newCode + "m;1;";
        }

        if (showInnerLines) {
            if (innerLineThickness != "2") {
                primaryOptionsModified = true;
                newCode = newCode + "0t;" + innerLineThickness + ";";
            }

            if (innerLineLengthX != "6") {
                primaryOptionsModified = true;
                newCode = newCode + "0l;" + innerLineLengthX + ";";
            }

            if (!innerLineLengthLink) {
                primaryOptionsModified = true;
                if (innerLineLengthY != "6") {
                    newCode = newCode + "0v;" + innerLineLengthY + ";";
                }

                newCode = newCode + "0g;1;";
            }

            if (innerLineOffset != "3") {
                primaryOptionsModified = true;
                newCode = newCode + "0o;" + innerLineOffset + ";";
            }

            if (innerLineOpacity != "0.8") {
                primaryOptionsModified = true;
                newCode = newCode + "0a;" + innerLineOpacity + ";";
            }

            if (innerLineMovementError) {
                primaryOptionsModified = true;
                newCode = newCode + "0m;1;";
            }

            if (!innerLineFiringError) {
                primaryOptionsModified = true;
                newCode = newCode + "0f;0;";
            }

            if (innerLineMovementError && innerLineMovementErrorMulitplier != "1") {
                primaryOptionsModified = true;
                newCode = newCode + "0s;" + innerLineMovementErrorMulitplier + ";";
            }

            if (innerLineFiringError && innerLineFiringErrorMulitplier != "1") {
                primaryOptionsModified = true;
                newCode = newCode + "0e;" + innerLineFiringErrorMulitplier + ";";
            }
        }
        else {
            primaryOptionsModified = true;
            newCode = newCode + "0b;0;";
        }

        if (showOuterLines) {
            if (outerLineThickness != "2") {
                primaryOptionsModified = true;
                newCode = newCode + "1t;" + outerLineThickness + ";";
            }

            if (outerLineLengthX != "2") {
                primaryOptionsModified = true;
                newCode = newCode + "1l;" + outerLineLengthX + ";";
            }

            if (!outerLineLengthLink) {
                primaryOptionsModified = true;
                if (outerLineLengthY != "2") {
                    newCode = newCode + "1v;" + outerLineLengthY + ";";
                }
                newCode = newCode + "1g;1;";
            }

            if (outerLineOffset != "10") {
                primaryOptionsModified = true;
                newCode = newCode + "1o;" + outerLineOffset + ";";
            }

            if (outerLineOpacity != "0.35") {
                primaryOptionsModified = true;
                newCode = newCode + "1a;" + outerLineOpacity + ";";
            }

            if (!outerLineMovementError) {
                primaryOptionsModified = true;
                newCode = newCode + "1m;0;";
            }

            if (!outerLineFiringError) {
                primaryOptionsModified = true;
                newCode = newCode + "1f;0;";
            }

            if (outerLineMovementError && outerLineMovementErrorMulitplier != "1") {
                primaryOptionsModified = true;
                newCode = newCode + "1s;" + outerLineMovementErrorMulitplier + ";";
            }

            if (outerLineFiringError && outerLineFiringErrorMulitplier != "1") {
                primaryOptionsModified = true;
                newCode = newCode + "1e;" + outerLineFiringErrorMulitplier + ";";
            }
        }
        else {
            primaryOptionsModified = true;
            newCode = newCode + "1b;0;";
        }

        if (advancedOptions) {
            if (!adscopyPrimaryCrosshair) {
                let adsOptionsModified = false;
                newCode = newCode + "ADS";

                if (adscolor.value == "8") {
                    adsOptionsModified = true;
                    newCode = newCode + "c;" + adscolor.value + ";";

                    if (adscolor.customUnicode && adscolor.isValid) {
                        newCode = newCode + "u;" + adscolor.customUnicode.replace("#", "") + "FF;";
                    }
                }
                else if (adscolor.value != "0") {
                    adsOptionsModified = true;
                    newCode = newCode + "c;" + adscolor.value + ";";
                }

                if (!adsoutlines) {
                    adsOptionsModified = true;
                    newCode = newCode + "h;0;";
                }
                else {
                    if (adsoutlineThickness != "1") {
                        adsOptionsModified = true;
                        newCode = newCode + "t;" + adsoutlineThickness + ";";
                    }

                    if (adsoutlineOpacity != "0.5") {
                        adsOptionsModified = true;
                        newCode = newCode + "o;" + parseFloat(adsoutlineOpacity).toString() + ";";
                    }
                }

                if (adscolor.value == "8") {
                    adsOptionsModified = true;
                    newCode = newCode + "b;1;";
                }

                if (adscenterDot) {
                    adsOptionsModified = true;
                    newCode = newCode + "d;1;";

                    if (adscenterDotThickness != "2") {
                        newCode = newCode + "z;" + adscenterDotThickness + ";";
                    }

                    const adscenterDotOpacityFloat = parseFloat(adscenterDotOpacity).toString();

                    if (adscenterDotOpacityFloat != "1") {
                        newCode = newCode + "a;" + adscenterDotOpacityFloat + ";";
                    }
                }

                if (adsoverrideFiringErrorOffsetWithOffset) {
                    adsOptionsModified = true;
                    newCode = newCode + "m;1;";
                }

                if (adsshowInnerLines) {
                    if (adsinnerLineThickness != "2") {
                        adsOptionsModified = true;
                        newCode = newCode + "0t;" + adsinnerLineThickness + ";";
                    }

                    if (adsinnerLineLengthX != "6") {
                        adsOptionsModified = true;
                        newCode = newCode + "0l;" + adsinnerLineLengthX + ";";
                    }

                    if (!adsinnerLineLengthLink) {
                        adsOptionsModified = true;
                        if (adsinnerLineLengthY != "6") {
                            newCode = newCode + "0v;" + adsinnerLineLengthY + ";";
                        }

                        newCode = newCode + "0g;1;";
                    }

                    if (adsinnerLineOffset != "3") {
                        adsOptionsModified = true;
                        newCode = newCode + "0o;" + adsinnerLineOffset + ";";
                    }

                    if (adsinnerLineOpacity != "0.8") {
                        adsOptionsModified = true;
                        newCode = newCode + "0a;" + adsinnerLineOpacity + ";";
                    }

                    if (adsinnerLineMovementError) {
                        adsOptionsModified = true;
                        newCode = newCode + "0m;1;";
                    }

                    if (!adsinnerLineFiringError) {
                        adsOptionsModified = true;
                        newCode = newCode + "0f;0;";
                    }

                    if (adsinnerLineMovementError && adsinnerLineMovementErrorMulitplier != "1") {
                        adsOptionsModified = true;
                        newCode = newCode + "0s;" + adsinnerLineMovementErrorMulitplier + ";";
                    }

                    if (adsinnerLineFiringError && adsinnerLineFiringErrorMulitplier != "1") {
                        adsOptionsModified = true;
                        newCode = newCode + "0e;" + adsinnerLineFiringErrorMulitplier + ";";
                    }
                }
                else {
                    adsOptionsModified = true;
                    newCode = newCode + "0b;0;";
                }

                if (adsshowOuterLines) {
                    if (adsouterLineThickness != "2") {
                        adsOptionsModified = true;
                        newCode = newCode + "1t;" + adsouterLineThickness + ";";
                    }

                    if (adsouterLineLengthX != "2") {
                        adsOptionsModified = true;
                        newCode = newCode + "1l;" + adsouterLineLengthX + ";";
                    }

                    if (!adsouterLineLengthLink) {
                        adsOptionsModified = true;
                        if (adsouterLineLengthY != "2") {
                            newCode = newCode + "1v;" + adsouterLineLengthY + ";";
                        }
                        newCode = newCode + "1g;1;";
                    }

                    if (adsouterLineOffset != "10") {
                        adsOptionsModified = true;
                        newCode = newCode + "1o;" + adsouterLineOffset + ";";
                    }

                    if (adsouterLineOpacity != "0.35") {
                        adsOptionsModified = true;
                        newCode = newCode + "1a;" + adsouterLineOpacity + ";";
                    }

                    if (!adsouterLineMovementError) {
                        adsOptionsModified = true;
                        newCode = newCode + "1m;0;";
                    }

                    if (!adsouterLineFiringError) {
                        adsOptionsModified = true;
                        newCode = newCode + "1f;0;";
                    }

                    if (adsouterLineMovementError && adsouterLineMovementErrorMulitplier != "1") {
                        adsOptionsModified = true;
                        newCode = newCode + "1s;" + adsouterLineMovementErrorMulitplier + ";";
                    }

                    if (adsouterLineFiringError && adsouterLineFiringErrorMulitplier != "1") {
                        adsOptionsModified = true;
                        newCode = newCode + "1e;" + adsouterLineFiringErrorMulitplier + ";";
                    }
                }
                else {
                    adsOptionsModified = true;
                    newCode = newCode + "1b;0;";
                }

                newCode = newCode.replace("ADS", (adsOptionsModified ? "A;" : ""));
            }

            let sniperOptionsModified = false;
            newCode = newCode + "SNIPER";

            if (sniperColor.value == "8") {
                sniperOptionsModified = true;
                newCode = newCode + "c;" + sniperColor.value + ";";

                if (sniperColor.customUnicode && sniperColor.isValid) {
                    newCode = newCode + "t;" + sniperColor.customUnicode.replace("#", "") + "FF;";
                }
            }
            else if (sniperColor.value != "7") {
                sniperOptionsModified = true;
                newCode = newCode + "c;" + sniperColor.value + ";";
            }

            if (sniperCenterDot) {
                const sniperCenterDotThicknessFloat = parseFloat(sniperCenterDotThickness).toString();

                if (sniperCenterDotThicknessFloat != "1") {
                    sniperOptionsModified = true;
                    newCode = newCode + "s;" + sniperCenterDotThicknessFloat + ";";
                }

                const sniperCenterDotOpacityFloat = parseFloat(sniperCenterDotOpacity).toString();

                if (sniperCenterDotOpacityFloat != "0.75") {
                    sniperOptionsModified = true;
                    newCode = newCode + "o;" + sniperCenterDotOpacityFloat + ";";
                }
            }
            else {
                sniperOptionsModified = true;
                newCode = newCode + "d;0;";
            }

            newCode = newCode.replace("SNIPER", (sniperOptionsModified ? "S;" : ""));
        }

        newCode = newCode.replace("PRIMARY", (primaryOptionsModified ? "P;" : ""));

        if (newCode.endsWith(";")) {
            newCode = newCode.substring(0, newCode.length - 1);
        }
        setCode(newCode);
    }



    return (
        <React.Fragment>
            <form className="crosshair-settings" onSubmit={(event: any) => {
                event.preventDefault();
            }} onKeyDown={(event: any) => {
                if (event?.target?.type == "text" && event?.key == "Enter") {
                    event.preventDefault();
                }
            }}>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className={"" + (activeTab == "GENERAL" ? " active" : "")} onClick={() => {
                            setActiveTab("GENERAL");
                            setCrosshairDisplay(CrosshairCanvasDisplayEnum.General);
                        }}
                            id="GENERAL-tab" >GENERAL</button>
                    </li>
                    <li className="nav-item" role="presentation" onClick={(event: any) => {
                        event.preventDefault();
                    }}>
                        <button className={"" + (activeTab == "PRIMARY" ? " active" : "")} role="tab" onClick={() => {
                            setActiveTab("PRIMARY");
                            setCrosshairDisplay(CrosshairCanvasDisplayEnum.Primary);
                        }} id="PRIMARY-tab" aria-selected={activeTab == "PRIMARY"}>PRIMARY</button>
                    </li>
                    {
                        advancedOptions && <React.Fragment>
                            <li className="nav-item" role="presentation" onClick={(event: any) => {
                                event.preventDefault();
                            }}>
                                <button className={"" + (activeTab == "ADS" ? " active" : "")} role="tab" onClick={() => {
                                    setActiveTab("ADS");
                                    setCrosshairDisplay(CrosshairCanvasDisplayEnum.Advanced);
                                }} id="ADS-tab" aria-selected={activeTab == "ADS"}>AIM DOWN SIGHTS</button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={(event: any) => {
                                event.preventDefault();
                            }}>
                                <button className={"" + (activeTab == "SNIPER" ? " active" : "")} role="tab" onClick={() => {
                                    setActiveTab("SNIPER");
                                    setCrosshairDisplay(CrosshairCanvasDisplayEnum.Sniper);
                                }} id="SNIPER-tab" aria-selected={activeTab == "SNIPER"}>SNIPER SCOPE</button>
                            </li>
                        </React.Fragment>
                    }
                </ul>
                <CrosshairCanvas code={code} displaySetting={crosshairDisplay}></CrosshairCanvas>
                <div className="form-group row options-container">
                    <label htmlFor="optionButtons" className="col-sm-8 col-md-4 col-form-label option-label">Crosshair Profile</label>
                    <div className="col-sm-12 col-md-8  option-buttons" id="optionButtons">
                        <button type="button" className={"btn btn-secondary btn-action"} onClick={() => {
                            navigator.clipboard.writeText(code);
                            toast.success("Code Copied", { theme: 'dark' });
                        }}>
                            <div className={"icon-custom icon-upload"}>
                            </div>
                            Copy Code
                        </button>
                        <button type="button" className={"btn btn-secondary btn-action"} onClick={async () => {
                            const text = await navigator.clipboard.readText();
                            initialized.current = false;
                            setCode(text);
                            toast.success("Code Pasted", { theme: 'dark' });
                        }}>
                            <div className={"icon-custom icon-download"}>
                            </div>
                            Paste Code</button>
                        <button type="button" className={"btn btn-secondary btn-action"} onClick={() => {
                            navigator.clipboard.writeText("https://www.crosshairvalorant.com/editor?code=" + code);
                            toast.success("Url Copied", { theme: 'dark' });
                        }}>
                            <div className={"icon-custom icon-share"}>
                            </div>
                            <div>
                                Share URL
                            </div>
                        </button>
                    </div>
                    <div className="col-sm-4">
                    </div>
                </div>
                {
                    activeTab == "GENERAL" &&
                    <div className="sub-setting-container">
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">CROSSHAIR</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="advancedOptionsButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Advanced Options</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="advancedOptionsButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (advancedOptions ? "selected-btn" : "")} onClick={() => {
                                            setAdvancedOptions(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!advancedOptions ? "selected-btn" : "")} onClick={() => {
                                            setAdvancedOptions(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">OTHER</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="showCrosshairOfSpectatedPlayerButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Show Crosshair Of Spectated Player</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="showCrosshairOfSpectatedPlayerButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (showCrosshairOfSpectatedPlayer ? "selected-btn" : "")} onClick={() => {
                                            setShowCrosshairOfSpectatedPlayer(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!showCrosshairOfSpectatedPlayer ? "selected-btn" : "")} onClick={() => {
                                            setShowCrosshairOfSpectatedPlayer(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="hideCrosshairOnFiringErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Hide Crosshair On Firing Error</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="hideCrosshairOnFiringErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (hideCrosshairOnFiringError ? "selected-btn" : "")} onClick={() => {
                                            setHideCrosshairOnFiringError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!hideCrosshairOnFiringError ? "selected-btn" : "")} onClick={() => {
                                            setHideCrosshairOnFiringError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    activeTab == "PRIMARY" &&
                    <div className="sub-setting-container">
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">CROSSHAIR</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="colorSelect" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Crosshair Color</label>
                                <div className="col-sm-6 col-md-3 d-flex setting-component-wrapper">
                                    <div className="colorDisplay" style={{
                                        backgroundColor: color.customUnicode && color.isValid ? (color.customUnicode.startsWith("#") ? color.customUnicode : "#" + color.customUnicode) : "white"
                                    }}></div>
                                    <input type="text" className="form-control text-center" onChange={onColorUnicodeChange} value={color.customUnicode} id="inputColorUnicode"></input>
                                </div>
                                <div className="col-sm-6 col-md-3 setting-component-wrapper">
                                    <select className="form-control" id="colorSelect" onChange={onColorChange} value={color.value}>
                                        {
                                            colorOptions.map((item: IColorOption, index: number) => {
                                                return <option key={index} value={item.value}>{item.text}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outlinesButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outlines</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="outlinesButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (outlines ? "selected-btn" : "")} onClick={() => {
                                            setOutlines(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!outlines ? "selected-btn" : "")} onClick={() => {
                                            setOutlines(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outlineOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outline Opacity</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOutlineOpacityChange} value={outlineOpacity} onBlur={onOutlineOpacityBlur} id="outlineOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="outlineOpacityRange" onChange={onOutlineOpacityChange} value={outlineOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outlineThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outline Thickness</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOutlineThicknessChange} value={outlineThickness} onBlur={onOutlineThicknessBlur} id="outlineThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="1" max="6" id="outlineThicknessRange" onChange={onOutlineThicknessChange} value={outlineThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="centerDotButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="centerDotButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (centerDot ? "selected-btn" : "")} onClick={() => {
                                            setCenterDot(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!centerDot ? "selected-btn" : "")} onClick={() => {
                                            setCenterDot(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="centerDotOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot Opacity</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onCenterDotOpacityChange} value={centerDotOpacity} onBlur={onCenterDotOpacityBlur} id="centerDotOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="centerDotOpacityRange" onChange={onCenterDotOpacityChange} value={centerDotOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="centerDotThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot Thickness</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onCenterDotThicknessChange} value={centerDotThickness} onBlur={onCenterDotThicknessBlur} id="centerDotThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="1" max="6" id="centerDotThicknessRange" onChange={onCenterDotThicknessChange} value={centerDotThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="overrideFiringErrorOffsetWithOffsetButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Override Firing Error Offset With Crosshair Offset</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="overrideFiringErrorOffsetWithOffsetButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (overrideFiringErrorOffsetWithOffset ? "selected-btn" : "")} onClick={() => {
                                            setOverrideFiringErrorOffsetWithOffset(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!overrideFiringErrorOffsetWithOffset ? "selected-btn" : "")} onClick={() => {
                                            setOverrideFiringErrorOffsetWithOffset(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">INNER LINES</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="showInnerLinesButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Show InnerLines</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="showInnerLinesButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (showInnerLines ? "selected-btn" : "")} onClick={() => {
                                            setShowInnerLines(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!showInnerLines ? "selected-btn" : "")} onClick={() => {
                                            setShowInnerLines(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Opacity</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onInnerLineOpacityChange} value={innerLineOpacity} onBlur={onInnerLineOpacityBlur} id="innerLineOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="innerLineOpacityRange" onChange={onInnerLineOpacityChange} value={innerLineOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineLengthRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Length</label>
                                <div className="col-sm-2 col-md-1 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onInnerLineLengthXChange} value={innerLineLengthX} onBlur={onInnerLineLengthXBlur} id="innerLineLengthXInput"></input>
                                </div>
                                <div className="col-sm-2 col-md-1 setting-component-wrapper">
                                    <input disabled={innerLineLengthLink} type="numeric" className="form-control text-center" onChange={onInnerLineLengthYChange} value={innerLineLengthY} onBlur={onInnerLineLengthYBlur} id="innerLineLengthYInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="20" id="innerLineLengthXRange" onChange={onInnerLineLengthXChange} value={innerLineLengthX}></input>
                                    </div>
                                    <div className={innerLineLengthLink ? "icon-custom icon-link" : "icon-custom icon-unlink"} onClick={() => {
                                        if (!innerLineLengthLink) {
                                            setInnerLineLengthY(innerLineLengthX);
                                        }
                                        setInnerLineLengthLink(!innerLineLengthLink);
                                    }}>
                                    </div>
                                    <div className='slidercontainer'>
                                        <input disabled={innerLineLengthLink} type="range" className="slider" min="0" max="20" id="innerLineLengthYRange" onChange={onInnerLineLengthYChange} value={innerLineLengthY}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Thickness</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onInnerLineThicknessChange} value={innerLineThickness} onBlur={onInnerLineThicknessBlur} id="innerLineThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="10" id="innerLineThicknessRange" onChange={onInnerLineThicknessChange} value={innerLineThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineOffsetRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Offset</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onInnerLineOffsetChange} value={innerLineOffset} onBlur={onInnerLineOffsetBlur} id="innerLineOffsetInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="20" id="innerLineOffsetRange" onChange={onInnerLineOffsetChange} value={innerLineOffset}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineMovementErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="innerLineMovementErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (innerLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            setInnerLineMovementError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!innerLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            setInnerLineMovementError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineMovementErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onInnerLineMovementErrorMulitplierChange} value={innerLineMovementErrorMulitplier} onBlur={onInnerLineMovementErrorMulitplierBlur} id="innerLineMovementErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="innerLineMovementErrorMulitplierRange" onChange={onInnerLineMovementErrorMulitplierChange} value={innerLineMovementErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineFiringErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="innerLineFiringErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (innerLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            setInnerLineFiringError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!innerLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            setInnerLineFiringError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="innerLineFiringErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onInnerLineFiringErrorMulitplierChange} value={innerLineFiringErrorMulitplier} onBlur={onInnerLineFiringErrorMulitplierBlur} id="innerLineFiringErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="innerLineFiringErrorMulitplierRange" onChange={onInnerLineFiringErrorMulitplierChange} value={innerLineFiringErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">OUTER LINES</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="showOuterLinesButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Show OuterLines</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="showOuterLinesButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (showOuterLines ? "selected-btn" : "")} onClick={() => {
                                            setShowOuterLines(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!showOuterLines ? "selected-btn" : "")} onClick={() => {
                                            setShowOuterLines(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Opacity</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOuterLineOpacityChange} value={outerLineOpacity} onBlur={onOuterLineOpacityBlur} id="outerLineOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="outerLineOpacityRange" onChange={onOuterLineOpacityChange} value={outerLineOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineLengthRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Length</label>
                                <div className="col-sm-2 col-md-1 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOuterLineLengthXChange} value={outerLineLengthX} onBlur={onOuterLineLengthXBlur} id="outerLineLengthXInput"></input>
                                </div>
                                <div className="col-sm-2 col-md-1 setting-component-wrapper">
                                    <input disabled={outerLineLengthLink} type="numeric" className="form-control text-center" onChange={onOuterLineLengthYChange} value={outerLineLengthY} onBlur={onOuterLineLengthYBlur} id="outerLineLengthYInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="20" id="outerLineLengthXRange" onChange={onOuterLineLengthXChange} value={outerLineLengthX}></input>
                                    </div>
                                    <div className={outerLineLengthLink ? "icon-custom icon-link" : "icon-custom icon-unlink"} onClick={() => {
                                        if (!outerLineLengthLink) {
                                            setOuterLineLengthY(outerLineLengthX);
                                        }
                                        setOuterLineLengthLink(!outerLineLengthLink);
                                    }}>
                                    </div>
                                    <div className='slidercontainer'>
                                        <input disabled={outerLineLengthLink} type="range" className="slider" min="0" max="20" id="outerLineLengthYRange" onChange={onOuterLineLengthYChange} value={outerLineLengthY}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Thickness</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOuterLineThicknessChange} value={outerLineThickness} onBlur={onOuterLineThicknessBlur} id="outerLineThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="10" id="outerLineThicknessRange" onChange={onOuterLineThicknessChange} value={outerLineThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineOffsetRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Offset</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOuterLineOffsetChange} value={outerLineOffset} onBlur={onOuterLineOffsetBlur} id="outerLineOffsetInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="40" id="outerLineOffsetRange" onChange={onOuterLineOffsetChange} value={outerLineOffset}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineMovementErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="outerLineMovementErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (outerLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            setOuterLineMovementError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!outerLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            setOuterLineMovementError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineMovementErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOuterLineMovementErrorMulitplierChange} value={outerLineMovementErrorMulitplier} onBlur={onOuterLineMovementErrorMulitplierBlur} id="outerLineMovementErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="outerLineMovementErrorMulitplierRange" onChange={onOuterLineMovementErrorMulitplierChange} value={outerLineMovementErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineFiringErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="outerLineFiringErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (outerLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            setOuterLineFiringError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!outerLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            setOuterLineFiringError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="outerLineFiringErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onOuterLineFiringErrorMulitplierChange} value={outerLineFiringErrorMulitplier} onBlur={onOuterLineFiringErrorMulitplierBlur} id="outerLineFiringErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="outerLineFiringErrorMulitplierRange" onChange={onOuterLineFiringErrorMulitplierChange} value={outerLineFiringErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    activeTab == "ADS" &&
                    <div className="sub-setting-container">
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">GENERAL</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsCopyPrimaryCrosshairButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Copy Primary Crosshair</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsCopyPrimaryCrosshairButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adscopyPrimaryCrosshair ? "selected-btn" : "")} onClick={() => {
                                            adssetCopyPrimaryCrosshair(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adscopyPrimaryCrosshair ? "selected-btn" : "")} onClick={() => {
                                            adssetCopyPrimaryCrosshair(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">CROSSHAIR</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adscolorSelect" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Crosshair Color</label>
                                <div className="col-sm-6 col-md-3 d-flex adssetting-component-wrapper">
                                    <div className="colorDisplay" style={{
                                        backgroundColor: adscolor.customUnicode && adscolor.isValid ? (adscolor.customUnicode.startsWith("#") ? adscolor.customUnicode : "#" + adscolor.customUnicode) : "white"
                                    }}></div>
                                    <input type="text" className="form-control text-center" onChange={onadsColorUnicodeChange} value={adscolor.customUnicode} id="adsinputColorUnicode"></input>
                                </div>
                                <div className="col-sm-6 col-md-3 adssetting-component-wrapper">
                                    <select className="form-control" id="adscolorSelect" onChange={onadsColorChange} value={adscolor.value}>
                                        {
                                            colorOptions.map((item: IColorOption, index: number) => {
                                                return <option key={index} value={item.value}>{item.text}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsoutlinesButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outlines</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsoutlinesButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsoutlines ? "selected-btn" : "")} onClick={() => {
                                            adssetOutlines(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsoutlines ? "selected-btn" : "")} onClick={() => {
                                            adssetOutlines(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsoutlineOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outline Opacity</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOutlineOpacityChange} value={adsoutlineOpacity} onBlur={onadsOutlineOpacityBlur} id="adsoutlineOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="adsoutlineOpacityRange" onChange={onadsOutlineOpacityChange} value={adsoutlineOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsoutlineThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outline Thickness</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOutlineThicknessChange} value={adsoutlineThickness} onBlur={onadsOutlineThicknessBlur} id="adsoutlineThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="1" max="6" id="adsoutlineThicknessRange" onChange={onadsOutlineThicknessChange} value={adsoutlineThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adscenterDotButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adscenterDotButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adscenterDot ? "selected-btn" : "")} onClick={() => {
                                            adssetCenterDot(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adscenterDot ? "selected-btn" : "")} onClick={() => {
                                            adssetCenterDot(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adscenterDotOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot Opacity</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsCenterDotOpacityChange} value={adscenterDotOpacity} onBlur={onadsCenterDotOpacityBlur} id="adscenterDotOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="adscenterDotOpacityRange" onChange={onadsCenterDotOpacityChange} value={adscenterDotOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adscenterDotThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot Thickness</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsCenterDotThicknessChange} value={adscenterDotThickness} onBlur={onadsCenterDotThicknessBlur} id="adscenterDotThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="1" max="6" id="adscenterDotThicknessRange" onChange={onadsCenterDotThicknessChange} value={adscenterDotThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsoverrideFiringErrorOffsetWithOffsetButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Override Firing Error Offset With Crosshair Offset</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsoverrideFiringErrorOffsetWithOffsetButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsoverrideFiringErrorOffsetWithOffset ? "selected-btn" : "")} onClick={() => {
                                            adssetOverrideFiringErrorOffsetWithOffset(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsoverrideFiringErrorOffsetWithOffset ? "selected-btn" : "")} onClick={() => {
                                            adssetOverrideFiringErrorOffsetWithOffset(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">INNER LINES</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsshowInnerLinesButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Show InnerLines</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsshowInnerLinesButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsshowInnerLines ? "selected-btn" : "")} onClick={() => {
                                            adssetShowInnerLines(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsshowInnerLines ? "selected-btn" : "")} onClick={() => {
                                            adssetShowInnerLines(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Opacity</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsInnerLineOpacityChange} value={adsinnerLineOpacity} onBlur={onadsInnerLineOpacityBlur} id="adsinnerLineOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="adsinnerLineOpacityRange" onChange={onadsInnerLineOpacityChange} value={adsinnerLineOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineLengthRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Length</label>
                                <div className="col-sm-2 col-md-1 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsInnerLineLengthXChange} value={adsinnerLineLengthX} onBlur={onadsInnerLineLengthXBlur} id="adsinnerLineLengthXInput"></input>
                                </div>
                                <div className="col-sm-2 col-md-1 adssetting-component-wrapper">
                                    <input disabled={adsinnerLineLengthLink} type="numeric" className="form-control text-center" onChange={onadsInnerLineLengthYChange} value={adsinnerLineLengthY} onBlur={onadsInnerLineLengthYBlur} id="adsinnerLineLengthYInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="20" id="adsinnerLineLengthXRange" onChange={onadsInnerLineLengthXChange} value={adsinnerLineLengthX}></input>
                                    </div>
                                    <div className={adsinnerLineLengthLink ? "icon-custom icon-link" : "icon-custom icon-unlink"} onClick={() => {
                                        if (!adsinnerLineLengthLink) {
                                            adssetInnerLineLengthY(adsinnerLineLengthX);
                                        }
                                        adssetInnerLineLengthLink(!adsinnerLineLengthLink);
                                    }}>
                                    </div>
                                    <div className='slidercontainer'>
                                        <input disabled={adsinnerLineLengthLink} type="range" className="slider" min="0" max="20" id="adsinnerLineLengthYRange" onChange={onadsInnerLineLengthYChange} value={adsinnerLineLengthY}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Thickness</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsInnerLineThicknessChange} value={adsinnerLineThickness} onBlur={onadsInnerLineThicknessBlur} id="adsinnerLineThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="10" id="adsinnerLineThicknessRange" onChange={onadsInnerLineThicknessChange} value={adsinnerLineThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineOffsetRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Inner Line Offset</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsInnerLineOffsetChange} value={adsinnerLineOffset} onBlur={onadsInnerLineOffsetBlur} id="adsinnerLineOffsetInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="20" id="adsinnerLineOffsetRange" onChange={onadsInnerLineOffsetChange} value={adsinnerLineOffset}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineMovementErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsinnerLineMovementErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsinnerLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            adssetInnerLineMovementError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsinnerLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            adssetInnerLineMovementError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineMovementErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsInnerLineMovementErrorMulitplierChange} value={adsinnerLineMovementErrorMulitplier} onBlur={onadsInnerLineMovementErrorMulitplierBlur} id="adsinnerLineMovementErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="adsinnerLineMovementErrorMulitplierRange" onChange={onadsInnerLineMovementErrorMulitplierChange} value={adsinnerLineMovementErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineFiringErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsinnerLineFiringErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsinnerLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            adssetInnerLineFiringError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsinnerLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            adssetInnerLineFiringError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsinnerLineFiringErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsInnerLineFiringErrorMulitplierChange} value={adsinnerLineFiringErrorMulitplier} onBlur={onadsInnerLineFiringErrorMulitplierBlur} id="adsinnerLineFiringErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="adsinnerLineFiringErrorMulitplierRange" onChange={onadsInnerLineFiringErrorMulitplierChange} value={adsinnerLineFiringErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">OUTER LINES</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsshowOuterLinesButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Show OuterLines</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsshowOuterLinesButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsshowOuterLines ? "selected-btn" : "")} onClick={() => {
                                            adssetShowOuterLines(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsshowOuterLines ? "selected-btn" : "")} onClick={() => {
                                            adssetShowOuterLines(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Opacity</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOuterLineOpacityChange} value={adsouterLineOpacity} onBlur={onadsOuterLineOpacityBlur} id="adsouterLineOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="adsouterLineOpacityRange" onChange={onadsOuterLineOpacityChange} value={adsouterLineOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineLengthRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Length</label>
                                <div className="col-sm-2 col-md-1 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOuterLineLengthXChange} value={adsouterLineLengthX} onBlur={onadsOuterLineLengthXBlur} id="adsouterLineLengthXInput"></input>
                                </div>
                                <div className="col-sm-2 col-md-1 adssetting-component-wrapper">
                                    <input disabled={adsouterLineLengthLink} type="numeric" className="form-control text-center" onChange={onadsOuterLineLengthYChange} value={adsouterLineLengthY} onBlur={onadsOuterLineLengthYBlur} id="adsouterLineLengthYInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="20" id="adsouterLineLengthXRange" onChange={onadsOuterLineLengthXChange} value={adsouterLineLengthX}></input>
                                    </div>
                                    <div className={adsouterLineLengthLink ? "icon-custom icon-link" : "icon-custom icon-unlink"} onClick={() => {
                                        if (!adsouterLineLengthLink) {
                                            adssetOuterLineLengthY(adsouterLineLengthX);
                                        }
                                        adssetOuterLineLengthLink(!adsouterLineLengthLink);
                                    }}>
                                    </div>
                                    <div className='slidercontainer'>
                                        <input disabled={adsouterLineLengthLink} type="range" className="slider" min="0" max="20" id="adsouterLineLengthYRange" onChange={onadsOuterLineLengthYChange} value={adsouterLineLengthY}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Thickness</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOuterLineThicknessChange} value={adsouterLineThickness} onBlur={onadsOuterLineThicknessBlur} id="adsouterLineThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="10" id="adsouterLineThicknessRange" onChange={onadsOuterLineThicknessChange} value={adsouterLineThickness}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineOffsetRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Outer Line Offset</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOuterLineOffsetChange} value={adsouterLineOffset} onBlur={onadsOuterLineOffsetBlur} id="adsouterLineOffsetInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="40" id="adsouterLineOffsetRange" onChange={onadsOuterLineOffsetChange} value={adsouterLineOffset}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineMovementErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsouterLineMovementErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsouterLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            adssetOuterLineMovementError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsouterLineMovementError ? "selected-btn" : "")} onClick={() => {
                                            adssetOuterLineMovementError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineMovementErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Movement Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOuterLineMovementErrorMulitplierChange} value={adsouterLineMovementErrorMulitplier} onBlur={onadsOuterLineMovementErrorMulitplierBlur} id="adsouterLineMovementErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="adsouterLineMovementErrorMulitplierRange" onChange={onadsOuterLineMovementErrorMulitplierChange} value={adsouterLineMovementErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineFiringErrorButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error</label>
                                <div className="col-sm-12 col-md-6  adssetting-component-wrapper" id="adsouterLineFiringErrorButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (adsouterLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            adssetOuterLineFiringError(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!adsouterLineFiringError ? "selected-btn" : "")} onClick={() => {
                                            adssetOuterLineFiringError(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="adsouterLineFiringErrorMulitplierRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Firing Error Multiplier</label>
                                <div className="col-sm-4 col-md-2 adssetting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onadsOuterLineFiringErrorMulitplierChange} value={adsouterLineFiringErrorMulitplier} onBlur={onadsOuterLineFiringErrorMulitplierBlur} id="adsouterLineFiringErrorMulitplierInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 adssetting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="3" step={"0.001"} id="adsouterLineFiringErrorMulitplierRange" onChange={onadsOuterLineFiringErrorMulitplierChange} value={adsouterLineFiringErrorMulitplier}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    activeTab == "SNIPER" &&
                    <div className="sub-setting-container">
                        <div className="form-group row sub-setting-row-label">
                            <label className="sub-setting-label">GENERAL</label>
                        </div>
                        <div className="sub-setting-row-container">
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="sniperColorSelect" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot Color</label>
                                <div className="col-sm-6 col-md-3 d-flex setting-component-wrapper">
                                    <div className="colorDisplay" style={{
                                        backgroundColor: sniperColor.customUnicode && sniperColor.isValid ? (sniperColor.customUnicode.startsWith("#") ? sniperColor.customUnicode : "#" + sniperColor.customUnicode) : "white"
                                    }}></div>
                                    <input type="text" className="form-control text-center" onChange={onSniperColorUnicodeChange} value={sniperColor.customUnicode} id="inputSniperColorUnicode"></input>
                                </div>
                                <div className="col-sm-6 col-md-3 setting-component-wrapper">
                                    <select className="form-control" id="sniperColorSelect" onChange={onSniperColorChange} value={sniperColor.value}>
                                        {
                                            colorOptions.map((item: IColorOption, index: number) => {
                                                return <option key={index} value={item.value}>{item.text}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="sniperCenterDotButtons" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot</label>
                                <div className="col-sm-12 col-md-6  setting-component-wrapper" id="sniperCenterDotButtons">
                                    <div className="btn-group" role="group">
                                        <button type="button" className={"btn btn-secondary " + (sniperCenterDot ? "selected-btn" : "")} onClick={() => {
                                            setSniperCenterDot(true);
                                        }}>On</button>
                                        <button type="button" className={"btn btn-secondary " + (!sniperCenterDot ? "selected-btn" : "")} onClick={() => {
                                            setSniperCenterDot(false);
                                        }}>Off</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="sniperCenterDotOpacityRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot Opacity</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onSniperCenterDotOpacityChange} value={sniperCenterDotOpacity} onBlur={onSniperCenterDotOpacityBlur} id="sniperCenterDotOpacityInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="1" step={"0.001"} id="sniperCenterDotOpacityRange" onChange={onSniperCenterDotOpacityChange} value={sniperCenterDotOpacity}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row sub-setting-row">
                                <label htmlFor="sniperCenterDotThicknessRange" className="col-sm-12 col-md-6  col-form-label sub-setting-label">Center Dot Thickness</label>
                                <div className="col-sm-4 col-md-2 setting-component-wrapper">
                                    <input type="numeric" className="form-control text-center" onChange={onSniperCenterDotThicknessChange} value={sniperCenterDotThickness} onBlur={onSniperCenterDotThicknessBlur} id="sniperCenterDotThicknessInput"></input>
                                </div>
                                <div className="col-sm-8 col-md-4 d-flex p-r-10 setting-component-wrapper">
                                    <div className='slidercontainer'>
                                        <input type="range" className="slider" min="0" max="4" step={"0.001"} id="sniperCenterDotThicknessRange" onChange={onSniperCenterDotThicknessChange} value={sniperCenterDotThickness}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </form>

        </React.Fragment >
    );
}