import React from "react";

export function ContactUs() {
    return (
        <React.Fragment>
            <div >
                <h2 >Mail: info@crosshairvalorant.com</h2>
            </div>
        </React.Fragment>
    );
}