import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import crosshairsData from "../data/crosshairs.json";
import { CrosshairCanvas } from "../components/crosshairCanvas";
import { CrosshairCanvasDisplayEnum, ICrosshairConfig, IPageProps } from "../interfaces/interface";

export function Pro(props: IPageProps) {
    const filterCrossHairData = () => {
        if (props.searchValue) {
            return crosshairsData.filter((item) => {
                return item.isPro && item.name && item.name.toLowerCase().includes(props.searchValue.toLowerCase());
            });
        }
        else {
            return crosshairsData.filter((item) => {
                return item.isPro;
            });
        }
    }

    const [crosshairs, setCrosshairs] = useState<ICrosshairConfig[]>(filterCrossHairData());

    useEffect(() => {
        setCrosshairs(filterCrossHairData());
    }, [props.searchValue]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Crosshair Valorant</title>
                <meta name="description" content="Crosshair Valorant - Find Your Crosshair!" />
                <meta name="keywords" content="valorant crosshair, crosshair valorant, crosshair, valorant, valorant pro, valorant pro crosshair, pro team crosshairs, pro player crosshairs, valorant pro player, valorant pro player crosshair" />
                <meta property="og:title" content="Crosshair Valorant" />
                <meta property="og:description" content="Crosshair Valorant - Find Your Crosshair!" />
                <meta property="og:image" content="https://crosshairvalorant.com/crosshairvalorant-oi.jpg" />
            </Helmet>
            <div className="crosshair-canvas-previews">
                {crosshairs.length > 0 ? crosshairs.map((item, index) => {
                    return <CrosshairCanvas key={index} displaySetting={CrosshairCanvasDisplayEnum.Preview} code={item.code} name={item.name}></CrosshairCanvas>
                }) : <div className="no-result-text">No result found</div>};
            </div>
        </React.Fragment>
    );
}