import { Helmet } from "react-helmet";
import React, { ChangeEvent, useEffect, useState } from 'react';
import './App.scss';
import { CrosshairSettings } from "./components/corsshairSettings";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { HomePage } from "./pages/homePage";
import { Editor } from "./pages/editor";
import { Pro } from "./pages/pro";
import { Fun } from "./pages/fun";
import { Privacy } from "./pages/privacy";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga4";
import { ContactUs } from "./pages/contactUs";

function App() {
  const { pathname } = useLocation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    ReactGA.initialize("G-XQ44WXLE5C");
  }, [])

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }

  return (
    <React.Fragment>
      <div className="App container">
        <header className="d-flex flex-wrap w-100 border-bottom m-b-10">
          <ul className="menu nav nav-pills">
            <li className="nav-item menu-item">
              <Link to={"/"} className={"nav-link px-2 link-light"}>
                <img src={"./crosshairvalorant-web.png"} alt="logo" />
              </Link>
            </li>
            <li className="nav-item menu-item mobile-only d-flex m-b-10">
              <div className={"icon-custom icon-menu mobile-only"} onClick={() => {
                setMobileMenuVisible(!mobileMenuVisible);
              }}>
              </div>
              <input type="text" className="form-control mobile-only" placeholder="Search" value={searchValue} onChange={onSearch}></input>
            </li>
            <li className={"nav-item menu-item" + (mobileMenuVisible ? " mobile-only" : "")}>
              <Link to={"/pro"} className={"nav-link px-2 link-light " + (pathname == "/pro" ? "link-selected" : "")}>
                PRO CROSSHAIRS
              </Link>
            </li>
            <li className={"nav-item menu-item" + (mobileMenuVisible ? " mobile-only" : "")}>
              <Link to={"/fun"} className={"nav-link px-2 link-light " + (pathname == "/fun" ? "link-selected" : "")}>
                FUN CROSSHAIRS
              </Link>
            </li>
            <li className={"nav-item menu-item" + (mobileMenuVisible ? " mobile-only" : "")}>
              <Link to={"/editor"} className={"nav-link px-2 link-light " + (pathname == "/editor" ? "link-selected" : "")}>
                EDITOR
              </Link>
            </li>
            <li className="nav-item menu-item flex-end">
              <input type="text" className="form-control" placeholder="Search" value={searchValue} onChange={onSearch}></input>
            </li>
          </ul>
        </header>
        <Routes>
          <Route path="/" element={<HomePage searchValue={searchValue} />} />
          <Route path="/pro" element={<Pro searchValue={searchValue} />} />
          <Route path="/fun" element={<Fun searchValue={searchValue} />} />
          <Route path="/editor" element={<Editor />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <footer className="d-flex flex-wrap justify-content-between align-items-center border-top">
          <p className="col-md-4 mb-0 text-start">© 2024 crosshairvalorant.com</p>
          <ul className="nav col-md-4 justify-content-end">
            <li className="nav-item"><a href={"/contact-us"} className="nav-link px-2 ">Contact Us</a></li>
            <li className="nav-item"><a href={"/privacy"} className="nav-link px-2 ">Privacy</a></li>
          </ul>
        </footer>
      </div>
      <ToastContainer></ToastContainer>
    </React.Fragment>

  );
}

export default App;
