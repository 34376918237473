import React from "react";

export function Privacy() {
    return (
        <React.Fragment>
            <h1 className="cc-h1">Privacy and Cookie Policy</h1>
            <div style={{ textAlign: "justify" }}>
                <p>Privacy and user safety are our top priorities at crosshairvalorant.com. This privacy policy explains how information related to the use of the crosshairvalorant.com website is collected, used, and protected.</p>
                <h2>1. Information Collection and Usage:</h2>
                <p>When you visit the crosshairvalorant.com website, we automatically collect certain non-personal information. This information may include general demographic information such as your browser type, operating system, internet protocol (IP) address, and your device's model and version. This information is used to analyze the performance of our website, improve user experience, and ensure security.</p>
                <h2>2. Cookies:</h2>
                <p>crosshairvalorant.com uses cookies to ensure the efficient operation of the website and enhance user experience. Cookies provide a more personalized experience by remembering user preferences for future visits. We may also use cookies to perform traffic analysis, monitor website usage, and support marketing activities.</p>
                <h2>3. Third-Party Links:</h2>
                <p>crosshairvalorant.com may include links to third-party websites to provide users with more information. We do not have any responsibility for the privacy practices of these websites. When using links, we recommend being cautious and reviewing the privacy policy of the relevant website.</p>
                <h2>4. Protection of Personal Information:</h2>
                <p>crosshairvalorant.com does not sell, rent, or otherwise share collected personal information with third parties. We only use your personal information for specific purposes and take necessary measures to ensure its security.</p>
                <h2>5. Changes:</h2>
                <p>We may update our privacy policy from time to time. These changes will be published on our website and will reflect the current policy. We recommend regularly reviewing the privacy policy.</p>
                <p>By visiting crosshairvalorant.com, you agree to this privacy policy. If you have any questions regarding privacy, please feel free to contact us.</p>
                <p>Last updated: [20/01/2024]</p>
            </div>
        </React.Fragment>
    );
}