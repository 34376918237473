export interface ICrosshairSettingsProps {
    initialCode: string;
    onCodeChange?: (code: string) => void;
}

export enum CrosshairCanvasDisplayEnum {
    Preview,
    General,
    Primary,
    Advanced,
    Sniper
}

export interface ICrosshairCanvasProps {
    code?: string;
    name?: string;
    displaySetting: CrosshairCanvasDisplayEnum;
}

export interface IColorOption {
    text: string;
    value: string;
    unicode: string;
    isValid?: boolean;
    customUnicode?: string;
}

export interface IPageProps {
    searchValue: string;
}

export interface ICrosshairConfig {
    name: string;
    code: string;
    isPro?: boolean;
    isFun?: boolean;
}