import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CrosshairSettings } from "../components/corsshairSettings";
import { Helmet } from "react-helmet";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Editor() {
    let query = useQuery();

    const [code, setCode] = useState<string>(query.get("code") || "");

    return (
        <React.Fragment>
            <Helmet>
                <title>Crosshair Valorant</title>
                <meta name="description" content="Crosshair Valorant - Find Your Crosshair!" />
                <meta name="keywords" content="valorant crosshair editor, valorant crosshair builder, valorant crosshair generator, valorant crosshair, crosshair valorant, crosshair" />
                <meta property="og:title" content="Crosshair Valorant" />
                <meta property="og:description" content="Crosshair Valorant - Find Your Crosshair!" />
                <meta property="og:image" content="https://crosshairvalorant.com/crosshairvalorant-oi.jpg" />
            </Helmet>
            <CrosshairSettings initialCode={code}></CrosshairSettings>
        </React.Fragment>
    );
}